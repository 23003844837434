<template>
  <div class="layout">
    <!-- 左侧导航 -->
    <div class="menu">
      <Menu :fold='isFold'>
      </Menu>
    </div>
    <!-- 右侧内容 -->
    <div class="flex1" :class="isFold==false?'content':'content1'">
      <Content @changeShow='changeMenu' ></Content>
    </div>
  </div>
</template>

<script>
import Menu from './menu/index'
import Content from './content/index.vue'

export default {
  data () {
    return {
      isFold:false,
    }
  },
  methods:{
    changeMenu(){
      this.isFold=!this.isFold
    },
  },
  components: {
    Menu, Content
  }
}
</script>
<style  scoped lang="less">
.layout {
  // display: flex;

  .menu {
    background: #8088E1;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
    //滚动
    scrollbar-width: none;
    /* firefox */
    -ms-overflow-style: none;
    /* IE 10+ */
    overflow: auto;
  }
  // 隐藏滚动条
  .menu::-webkit-scrollbar {
      display: none;
    }
  .content {
    
    padding-left:200px ;
    transition: all 0.55s ease;
  }
  .content1{
      padding-left:64px ;
      
  }
}
</style>