<template>
    <div>
      <!-- 岗位管理 -->
      <el-dialog title="资料编辑" class="position_management" :visible="DialogFlag" width="600px"
        :before-close="handleClose" center>
        <el-form :model="ruleFormBm" :rules="rulesBm" ref="ruleFormBm" label-width="120px" class="demo-ruleForm">
          <el-form-item label="成员头像 :" class="atorImg" prop="avatar">
            <el-upload :action='webAPI + "/AliOss/imagePolicy?name=head1"' list-type="picture-card"
              :on-success="handleAvatarSuccess" :on-progress="onProgress" :before-upload="beforeAvatarUpload"
              :headers="token" :file-list="fileList" :limit="1">
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="成员名称 :" prop="nick">
            <el-input v-model="ruleFormBm.nick"></el-input>
          </el-form-item>
          <el-form-item label="手机号码 :" prop="phone">
            <el-input v-model="ruleFormBm.phone"></el-input>
          </el-form-item>
          <el-form-item label="密码 :" prop="password">
            <el-input v-model="ruleFormBm.password"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submitForm('ruleFormBm')">保 存</el-button>
        </span>
        <div v-if="isShowJinDuTiao" class="bacc">
          <div class="jindutiao">
            <el-progress type="circle" :percentage="curPercentage" :width="100" class="progressCircle"></el-progress>
          </div>
        </div>
      </el-dialog>
    </div>
  </template>
  <script>
  import { v4 as uuidv4 } from "uuid";
  import md5 from "crypto-js/md5";
  export default {
    props: {
      adminInfo: {
        type:Object,
        default: {}
      },
      DialogFlag: {
        default: false
      },
    },
    data() {
      return {
        curPercentage: 0, // 动态显示进度条
        isShowJinDuTiao: false, // 关闭进度条
        fileList: [],
        token: {},
        ruleFormBm: {
          avatar: '',
          nick: '',
          phone: '',
          password: '',
        },
        rulesBm: {
          avatar: [
            { required: true, message: '请上传成员头像', trigger: 'blur' }
          ],
          nick: [
            { required: true, message: '请填写成员名称', trigger: 'blur' }
          ],
        },
      }
    },
    watch: {
      DialogFlag: {
        handler: function (newVal, oldVal) {
          if (newVal) {
              this.id = this.adminInfo.id
              this.ruleFormBm = {
                avatar: this.adminInfo.avatar,
                nick: this.adminInfo.nick,
                phone: this.adminInfo.phone,
              }
              this.fileList = [{ url: this.imgUrl + this.adminInfo.avatar, uid: 100 }];
            
          } else {
            this.$refs.ruleFormBm.resetFields(); // 清空表单验证
            this.ruleFormBm = {
              avatar: '',
              nick: '',
              phone: '',
              password: '',
  
            }
            this.fileList = []
          }
        }
      },
  
    },
    mounted() {
      this.token = {
        Authorization: "Bearer " + localStorage.token,
      };
  
    },
    methods: {
      // 新增编辑后更新列表数据
      handleClose(done) {
        this.$emit('update:DialogFlag', false)
      },
      //上传图片
      handleAvatarSuccess(res, file) {
        // this.ruleFormBm.avatar = URL.createObjectURL(file.raw);
        this.UpImg(res, file);
      },
      //图片上传
      async UpImg(res, file) {
        res = res.data;
        let ss = uuidv4();
        let formdata = new FormData();
        formdata.append("policy", res.policy);
        formdata.append("key", res.dir + ss + ".png");
        formdata.append("OSSAccessKeyId", res.accessid);
        formdata.append("success_action_status", 200);
        formdata.append("signature", res.signature);
        formdata.append("file", file.raw);
        await this.$axios.post(res.host, formdata).then(response => {
          this.ruleFormBm.avatar = "/" + res.dir + ss + ".png";
          // localStorage.avatar = this.imgUrl + this.avatar
        });
      },
      onProgress(event, file, fileList) {
        let num = ((event.loaded / event.total) * 100) | 0; //百分比
        this.curPercentage = num;
        if (this.curPercentage == 100) {
          // 当文件上传完成后, 关闭进度条;
          this.isShowJinDuTiao = false;
          this.curPercentage = 0;
        }
      },
      // 上传之前的回调
      beforeAvatarUpload(file) {
        const isJPG = file.type === "image/jpeg" || file.type === "image/png";
        const isLt2M = file.size / 1024 / 1024 < 5;
  
        if (!isJPG) {
          this.$message.error("上传图片只能是 JPG 格式!");
        }
        if (!isLt2M) {
          this.$message.error("上传图片大小不能超过 5MB!");
        }
        if (isJPG && isLt2M) {
          this.isShowJinDuTiao = true;
        }
        return isJPG && isLt2M;
      },
      //编辑
      async edit() {
        let data = {
            ...this.ruleFormBm
        }
        if(this.ruleFormBm.password){
          data.password=md5(this.ruleFormBm.password)
        }

        // console.log(data)
        await this.$axios
          .post(this.webAPI + "/AdminUser/profile",data)
          .then((res) => {
            if (res.msg == "操作成功") {
              this.success(res.msg);
              localStorage.avatar = this.imgUrl + this.ruleFormBm.avatar
              this.$root.$emit("refreshTotalNum"); //-触发刷新待处理数量的事件
              this.handleClose()
            } else {
              this.error(res.msg);
            }
          });
          
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
              this.edit();
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      success(v) {
        this.$message({
          type: "success",
          message: v,
        });
      },
      error(v) {
        this.$message({
          type: "error",
          message: v,
        });
      },
    }
  }
  </script>
  <style scoped lang="less">
  .position_management {
    ::v-deep .el-dialog {
  
      position: relative;
    }
  
    ::v-deep .el-dialog__header {
      box-sizing: border-box;
      background: #78b3ee;
      padding: 10px;
    }
  
  
    ::v-deep .el-form-item__content {
      width: 55%;
  
    }
  
    .atorImg .el-form-item__content {
      >div {
        display: flex;
      }
    }
  
    ::v-deep .el-dialog__headerbtn {
      top: 14px;
  
      >.el-dialog__close {
        color: #fffd;
      }
  
    }
  
    ::v-deep .el-button {
      padding: 7px 20px;
    }
  
    ::v-deep .el-upload-list__item-thumbnail {
      object-fit: contain;
    }
  
    ::v-deep .el-form-item {
      margin-bottom: 16px;
  
    }
  
    .uploadAtaor {
      >div {
        display: flex;
      }
    }
  
    .h380 {
      height: 320px;
      overflow: auto;
    }
  
  }
  
  .bacc {
    /*bacc是灰色的大背景*/
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.6);
  
    /*这个类的作用是把进度条放到水平垂直居中的位置*/
    .jindutiao {
      position: absolute;
      width: 150px;
      height: 150px;
      top: 50%;
      left: 50%;
      margin-left: -75px;
      margin-top: -75px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  </style>