var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home bgh pdl20"},[_c('div',{staticClass:"number jcl aic gap24"},[_c('div',{staticClass:"column br6"},[_vm._m(0),_c('div',{staticClass:"co2"},[_vm._v(_vm._s(_vm.index.user_num))])]),_c('div',{staticClass:"column br6"},[_vm._m(1),_c('div',{staticClass:"co2"},[_vm._v(_vm._s(_vm.index.auth_num))])]),_c('div',{staticClass:"column br6"},[_vm._m(2),_c('div',{staticClass:"co2"},[_vm._v(_vm._s(_vm.index.product_num))])]),_c('div',{staticClass:"column br6"},[_vm._m(3),_c('div',{staticClass:"co2"},[_vm._v(_vm._s(_vm.index.order_num))])])]),_vm._m(4),_vm._m(5),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"co1"},[_vm._v("用户数量 "),_c('i',{staticClass:"co3"},[_vm._v("单位/人")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"co1"},[_vm._v("认证数量 "),_c('i',{staticClass:"co3"},[_vm._v("单位/个")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"co1"},[_vm._v("产品数量 "),_c('i',{staticClass:"co3"},[_vm._v("单位/件")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"co1"},[_vm._v("订单数量 "),_c('i',{staticClass:"co3"},[_vm._v("单位/个")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex jcl aic gap24 pt20"},[_c('div',{staticClass:"user1 br16 bgb"},[_c('div',{staticClass:"userName"},[_vm._v("认证用户占比")]),_c('div',{attrs:{"id":"user"}})]),_c('div',{staticClass:"firms1 br16 bgb"},[_c('div',{staticClass:"userName"},[_vm._v("认证数量-月份柱状图")]),_c('div',{attrs:{"id":"firms"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex jcl aic gap24 pt20"},[_c('div',{staticClass:"w48 br16 bgb"},[_c('div',{staticClass:"userName"},[_vm._v("用户数量")]),_c('div',{attrs:{"id":"userS"}})]),_c('div',{staticClass:"w48 br16 bgb"},[_c('div',{staticClass:"userName"},[_vm._v("认证数量")]),_c('div',{attrs:{"id":"authentication"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex jcl aic gap24 pt20"},[_c('div',{staticClass:"w48 br16 bgb"},[_c('div',{staticClass:"userName"},[_vm._v("产品数量")]),_c('div',{attrs:{"id":"production"}})]),_c('div',{staticClass:"w48 br16 bgb"},[_c('div',{staticClass:"userName"},[_vm._v("订单数量")]),_c('div',{attrs:{"id":"Order"}})])])
}]

export { render, staticRenderFns }