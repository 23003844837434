<template>
  <div class="body flex jcc">
    <div>
      <div class="logo">
        <div class="logobox flex aic">
          <div class="flex aic jcc">
            <div class="bglogo"></div>
            <div>
              <div class="white f18 saotuen">有一点商服</div>
              <div class="white f12 saotuzh">yoyodeal</div>
            </div>
          </div>
          <div class="par"></div>
          <div class="white hy">欢迎登录</div>
        </div>
      </div>
      <div class="login flex aic jcc">
        <div class="contentBox flex aic jcc">
          <div class="big"></div>
          <div class="toverify">
            <div class="pt20 textac f24">操作员中心</div>
            <div class="isline"></div>
            <div class="useName aic">
              <el-input placeholder="请输入邮箱/账户名" v-model.trim="username">
                <template slot="prepend"
                  ><i class="el-icon-user-solid f16"></i
                ></template>
              </el-input>
            </div>
            <div class="passWord aic pt20">
              <el-input
                placeholder="请输入密码"
                v-model.trim="password"
                show-password
              >
                <template slot="prepend"
                  ><i class="el-icon-lock f16"></i
                ></template>
              </el-input>
            </div>
            <div class="verify">
              <div id="captcha-box"></div>
            </div>
            <div class="Logined pt10">
              <div class="btn nobtn" v-if="!seccode&&!challenge">登录</div>
              <div class="btn" @click="login" v-else>登录</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CryptoJS from "crypto-js";
import md5 from "js-md5";
// import axios from "axios";
export default {
  data() {
    return {
      username: "",
      password: "",
      encryptedText: "",
      decryptedText: "",
      secretKey: "yourSecretKey",
      challenge: "", //极验证流水号
      seccode: "", //极验证代码
      encryptedPassword: "",
    };
  },
  mounted() {
    // this.demoApi()
    this.onInitGeetest();
    localStorage.removeItem("token");
    localStorage.removeItem("avatar");
    localStorage.removeItem("username");
    localStorage.removeItem("chat_id");
    this.$chat.logout();
  },
  methods: {
    //登录
    async login() {
      let data = {
        username: this.username,
        password: md5(this.password),
        challenge: this.challenge,
        seccode: this.seccode,
      };
      await this.$axios
        .post(this.webAPI + "/Passport/login", data)
        .then((res) => {
          if (res.code == 200) {
            localStorage.token = res.data.token;
            localStorage.avatar = this.imgUrl + res.data.avatar;
            localStorage.username = res.data.username;
            localStorage.chat_id = res.data.chat_id;
            localStorage.crm_role = res.data.crm_role;
            localStorage.department_id=res.data.department_id
            this.$router.push("/need/needCenter");
          } else {
            // 获取要删除的元素
let elementToRemove = document.getElementById('captcha-box');

// 检查元素是否存在
if (elementToRemove) {
    // 从父节点中移除元素
    elementToRemove.innerHTML = '';
} else {
    console.log('Element with id "captcha-box" not found.');
}
            this.onInitGeetest()
            this.info(res.msg);
          }
        });
    },
    info(e) {
      this.$message({
        message: e,
        type: "info",
      });
    },

    onInitGeetest() {
      this.$axios
        .post(this.webAPI + "/Passport/getGeetestConfig")
        .then((res) => {
          // 后端地址 initGeetest需要的参数
          console.log(res);
          const { data } = res;
          // console.log(data);
          initGeetest(
            // initGeetest直接使用就OK了 在main.js里面已经引用到了gt文件了 下面的对象是给initGeetest需要的参数也就是在接口上面获取到的 放进去就OK
            {
              gt: data.gt,
              challenge: data.challenge,
              success: data.success,
              new_captcha: true,
              width: "100%",
            },
            (captchaObj) => {
              // 这里可以调用验证实例 captchaObj 的实例方法
              console.log(captchaObj);
              // 通过调用captchaObj对象的appendTo方法，给某个标签添加上滑动验证对象，就行了，看写法
              captchaObj.appendTo("#captcha-box"); // 参数是一个标签选择器，那么这个标签就加上了滑动验证的页面效果
              captchaObj.onSuccess(() => {
                // 这里是滑动完毕回调
                // 通过这个captchaObj.getValidate()方法，会得到一个自定义对象，这个对象里面有三个数据，将来点击提交按钮的时候，不仅仅要提交用户名和密码，还要将这三个数据提交给后端进行验证
                let result = captchaObj.getValidate();
                console.log(result, "流水号");
                this.challenge = result.geetest_challenge; // 保存这个数据
                this.seccode = result.geetest_seccode; // 保存这个数据
              });
            }
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //错误提示
    error() {
      this.$message.error("请填写正确的登录账号和密码");
    },
    //解密
    decrypt() {
      const encryptedText = this.encryptedText;
      const bytes = CryptoJS.AES.decrypt(encryptedText, this.secretKey);
      this.password = bytes.toString(CryptoJS.enc.Utf8);
    },
    async demoApi() {
      let res = await this.$api.demoApi({
        word: "辣椒",
      });
      console.log(res);
    },
    //非对称加密
    encrypt() {
      //text加密文本
      const text = this.password;
      this.encryptedText = CryptoJS.AES.encrypt(
        text,
        this.secretKey
      ).toString();
    },
  },
};
</script>

<style scoped lang="less">
.body {
  width: 100vw;
  height: 100vh;
  background: url(../../assets/img/group.png) no-repeat center;
  background-size: 100% 100%;
}
.logo {
  width: 100vw;
  height: 60px;
  background: #8088e1;
  .logobox {
    width: 1300px;
    height: 60px;
    margin: 0 auto;
    .bglogo {
      width: 48px;
      height: 48px;
      background-size: 100%;
      margin: 0 10px;
      background: url(../../assets/img/logo.png) no-repeat center;
    }
    .saotuen {
      //width: 80px;
      height: 25px;
      line-height: 25px;
      font-weight: 700;
    }
    .par {
      width: 1px;
      height: 37px;
      line-height: 37px;
      margin: 0px 25px;
      background: #fff;
    }
    .hy {
      width: 138px;
      line-height: 41px;
      font-size: 26px;
    }
  }
}

.login {
  // width: 1920px;
  // height: 800px;
  margin-top: 120px;
  .contentBox {
    width: 856px;
    height: 470px;
    background: #fff;
    border-radius: 16px;
    .big {
      width: 372px;
      height: 415px;
      background: url(../../assets/img/Gop.png) no-repeat center;
      background-size: 100%;
    }
    .toverify {
      width: 450px;
      height: 415px;
      .isline {
        width: 306px;
        height: 1px;
        background: rgb(218, 214, 214);
        margin: 28px auto;
      }
    }
    .useName,
    .passWord {
      display: flex;
      width: 307px;
      font-size: 18px;
      margin: 0px auto;
      /deep/ .el-input__inner {
        // width: 307px;
        height: 40px;
      }
      .flex20 {
        flex-basis: 20%;
      }
      .flex80 {
        flex-basis: 80%;
      }
      input {
        width: 277px;
        height: 40px;
        font-size: 14px;
      }
    }
    .verify {
      width: 307px;
      height: 44px;
      margin: 20px auto;
    }
    .Logined {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      .el-button--primary {
        width: 200px;
      }

      .btn {
        width: 307px;
        background: linear-gradient(180deg, #7785f0, #6a7aee);
        border: 1px solid #6a7aee;
        border-radius: 50px;
        box-shadow: inset 0 -2px 0 0 #6a7aee, 0 3px 4px 0 #5967d3;
        padding: 14px 30px;
        cursor: pointer;
        height: 40px;
        transition: all 0.2s;
        font-size: 14px;
        letter-spacing: 1px;
        text-align: center;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
      }
      .btn:hover {
        box-shadow: 0 3px 4px 1px #4453c7;
        margin-top: -8px;
        i {
          padding-left: 4px;
        }
      }
      .nobtn{
        background:#ccc;
        box-shadow: inset 0 -2px 0 0 #ccc, 0 3px 4px 0 #ccc;
        border:1px solid #ccc;
        &:hover{
          box-shadow: 0 3px 4px 1px #ccc;
        }
      }
    }
  }
}
</style>
