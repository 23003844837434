<template>
  <pop v-show="isMemShow">
    <div class="w600">
      <div class="flex jcsb aic">
        <div class="f14">调动部门</div>
        <div @click="closeW" class="cp">
          <i class="el-icon-close f20"></i>
        </div>
      </div>
      <div class="pt10 pb10">
        <el-select v-model="department_id" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="pt10">
        <div class="flex jcr aic gap10">
          <div><el-button size="small" @click="closeW" >取消</el-button></div>
          <div>
            <el-button type="success" size="small" @click="queding" >确定</el-button>
          </div>
        </div>
      </div>
    </div>
  </pop>
</template>

<script>
export default {
  name: "TransferDepartment",
  data() {
    return {
      isMemShow: false,
      options: [],
      department_id: "",
    };
  },
  mounted() {
    this.isTrue = true;
    this.Department();
  },

  methods: {
    closeW() {
      this.isMemShow = false;
    },
    open() {
      this.isMemShow = true;
    },
    queding(){
        this.$root.$emit('diaodongbumen',this.department_id)
        this.closeW()
    },
    // 关闭弹窗
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    //获取部门
    async Department() {
      let data = {
        power: 1, //角色：-1=全部 0无 1crm
        name: "", //名称
      };
      await this.$axios
        .get(this.webAPI + "/AdminDepartment/simple", {
          params: data,
        })
        .then((res) => {
          if (res.code == 200) {
            // 请求成功，处理响应数据
            this.options = res.data.list;
            this.department_id = this.options[0].id;
          }
        });
    },
  },
  components: {},
};
</script>
<style scoped lang="less">
.w600 {
  width: 600px;
  // min-height: 750px;
}

.borBom1 {
  border-bottom: 1px solid #d4d4d4;
}
//按钮tab样式
.bo3 {
  border-bottom: 2px solid #4095e5;
}
.mt15 {
  margin-top: 15px;
}
.w270 {
  width: 270px;
}
.w150 {
  width: 135px;
}

</style>
