<template>
  <pop v-show="isOpenShow">
    <div class="w700 pr">
      <div class="flex jcr aic pa wtrp1">
        <i class="el-icon-close f18 cp wtrp2" @click="CloseShow"></i>
      </div>
      <div class="pt10"></div>
      <div class="flex aic jcsb ba80">
        <div class="flex aic">
          <div class="flex aic mr70">
            <div class="w70 textar mr10">跟进主体:</div>
            <div>{{ tablist.company_name }}</div>
          </div>
          <div class="flex aic">
            <div class="w70 textar mr10">跟进人:</div>
            <div>{{ tablist.user_nick }}</div>
          </div>
        </div>
        <div>
          <el-button size="small " type="primary" @click="openlianxiren">联系人列表</el-button>
        </div>
      </div>
      <div class="flex aic jcsb mt30 borBom1 pdb20">
        <div class="flex aic jcsb flex60 ba80">
          <div class="pdb10 cp" :class="activate == -1 ? 'blue bo3' : ''" @click="chose(-1)">
            编辑
          </div>
          <div class="pdb10 cp" :class="activate == 0 ? 'blue bo3' : ''" @click="chose(0)">
            商机
          </div>
          <div class="pdb10 cp" :class="activate == 1 ? 'blue bo3' : ''" @click="chose(1)">
            跟进
          </div>
        </div>
        <div class="flex aic jcr gap24 flex30">
          <div v-if="!closeBtn && disabled && activate == -1">
            <el-button size="mini " type="primary" @click="isChangeList">编辑</el-button>
          </div>
          <div v-if="!closeBtn && !disabled && activate == -1">
            <el-button size="mini " type="danger" @click="saveList">保存</el-button>
          </div>
        </div>
      </div>
      <div class="flex aic jcsb mt20 borBom1 pdb20" v-if="activate == -1">
        <div class="flex aic jcsb flex25 ba80">
          <div class="pdb10 cp" :class="IndAndCom == 1 ? 'blue bo3' : ''" @click="chose2(1)">
            工商信息
          </div>
          <div class="pdb10 cp" :class="IndAndCom == 2 ? 'blue bo3' : ''" @click="chose2(2)">
            运营信息
          </div>
        </div>
      </div>
      <div v-if="IndAndCom == 1 && activate == -1">
        <div class="flex aic jcsb mt20">
          <div class="flex aic flex50">
            <div class="w130 textar mr20 ba80">所属部门:</div>
            <div>
              <el-select v-model="tablist.department_id" class="w270" :disabled="disabled" placeholder="请选择">
                <el-option v-for="item in options1" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </div>
          </div>
          <div class="flex aic flex50">
            <div class="w130 textar mr20 ba80">更多邮箱:</div>
            <div class="flex60">
              <el-input v-model="tablist.more_email" :disabled="disabled" placeholder="请输入内容"></el-input>
            </div>
          </div>
        </div>
        <div class="flex aic jcsb mt15">
          <div class="flex aic flex50">
            <div class="w130 textar mr20 ba80">企业名称:</div>
            <div class="flex60">
              <el-input v-model="tablist.company_name" :disabled="disabled" placeholder="请输入内容"></el-input>
            </div>
          </div>
          <div class="flex aic flex50">
            <div class="w130 textar mr20 ba80">企业规模:</div>
            <div class="flex60">
              <el-select v-model="tablist.company_size" :disabled="disabled" class="w270" placeholder="请选择">
                <el-option v-for="item in qiyeguimoList" :key="item.value" :label="item.key"
                  :value="item.value"></el-option>
              </el-select>
              <!-- <el-input v-model="tablist.company_size" placeholder="请输入内容"></el-input> -->
            </div>
          </div>
        </div>
        <div class="flex aic jcsb mt15">
          <div class="flex aic flex50">
            <div class="w130 textar mr20 ba80">法定代表人:</div>
            <div class="flex60">
              <el-input :disabled="disabled" v-model="tablist.legal_representative" placeholder="请输入内容"></el-input>
            </div>
          </div>
          <div class="flex aic flex50">
            <div class="w130 textar mr20 ba80">国标行业门类:</div>
            <div class="flex60">
              <el-input :disabled="disabled" v-model="tablist.iic" placeholder="请输入内容"></el-input>
            </div>
          </div>
        </div>
        <div class="flex aic jcsb mt15">
          <div class="flex aic flex50">
            <div class="w130 textar mr20 ba80">登记状态:</div>
            <div>
              <el-select :disabled="disabled" v-model="tablist.company_status" class="w270" placeholder="请选择">
                <el-option v-for="item in dengjizhuangtaiList" :key="item.value" :label="item.key"
                  :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
          <div class="flex aic flex50">
            <div class="w130 textar mr20 ba80">国际行业大类:</div>
            <div class="flex60">
              <el-input :disabled="disabled" v-model="tablist.iic2" placeholder="请输入内容"></el-input>
            </div>
          </div>
        </div>
        <div class="flex aic jcsb mt15">
          <div class="flex aic flex50">
            <div class="w130 textar mr20 ba80">注册资本:</div>
            <div class="flex60">
              <el-input :disabled="disabled" v-model="tablist.registered_capital" placeholder="请输入内容"></el-input>
            </div>
          </div>
          <div class="flex aic flex50">
            <div class="w130 textar mr20 ba80">国际行业中类:</div>
            <div class="flex60">
              <el-input :disabled="disabled" v-model="tablist.iic3" placeholder="请输入内容"></el-input>
            </div>
          </div>
        </div>
        <div class="flex aic jcsb mt15">
          <div class="flex aic flex50">
            <div class="w130 textar mr20 ba80">成立日期:</div>
            <div class="flex60">
              <!-- <el-input
              :disabled="disabled"
                v-model="tablist.establishment_date"
                placeholder="请输入内容"
              ></el-input> -->
              <el-date-picker :disabled="disabled" v-model="tablist.establishment_date" type="date" class="w100s"
                value-format="yyyy-MM-dd" placeholder="选择日期">
              </el-date-picker>
            </div>
          </div>
          <div class="flex aic flex50">
            <div class="w130 textar mr20 ba80">国际行业小类:</div>
            <div class="flex60">
              <el-input :disabled="disabled" v-model="tablist.iic4" placeholder="请输入内容"></el-input>
            </div>
          </div>
        </div>
        <div class="flex aic jcsb mt15">
          <div class="flex aic flex50">
            <div class="w130 textar mr20 ba80">社会统一信用代码:</div>
            <div class="flex60">
              <el-input :disabled="disabled" v-model="tablist.license_no" placeholder="请输入内容"></el-input>
            </div>
          </div>
          <div class="flex aic flex50">
            <div class="w130 textar mr20 ba80">所在地:</div>
            <div class="flex60">
              <!-- <el-select v-model="tablist.addr" class="w270" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select> -->
              <div class="ls1" v-if="disabled">{{tablist.province_name  }}-{{tablist.city_name  }}-{{tablist.district_name  }} </div>
              <el-cascader ref="cascader"  v-if="!disabled" :props="props" v-model="tablist.value" @change="handleChange4"
                placeholder="请选择" class="w100s"></el-cascader>
            </div>
          </div>
        </div>
        <div class="flex aic jcsb mt15">
          <div class="flex aic flex50">
            <div class="w130 textar mr20 ba80">电话:</div>
            <div class="flex60">
              <el-input :disabled="disabled" v-model="tablist.tel" placeholder="请输入内容"></el-input>
            </div>
          </div>
          <div class="flex aic flex50">
            <div class="w130 textar mr20 ba80">企业官网:</div>
            <div class="flex60">
              <el-input :disabled="disabled" v-model="tablist.company_web" placeholder="请输入内容"></el-input>
            </div>
          </div>
        </div>
        <div class="flex aic jcsb mt15">
          <div class="flex aic flex50">
            <div class="w130 textar mr20 ba80">邮箱:</div>
            <div class="flex60">
              <el-input :disabled="disabled" v-model="tablist.email" placeholder="请输入内容"></el-input>
            </div>
          </div>
          <div class="flex aic flex50">
            <div class="w130 textar mr20 ba80">企业注册地址:</div>
            <div class="flex60">
              <el-input :disabled="disabled" v-model="tablist.addr" placeholder="请输入内容"></el-input>
            </div>
          </div>
        </div>
        <div class="flex mt15">
          <div class="w150 textar mr20 ba80">经营范围:</div>
          <el-input type="textarea" class="w600" :rows="2" :disabled="disabled" placeholder="请输入内容"
            v-model="tablist.company_space"></el-input>
        </div>
      </div>
      <div class="pb10" v-if="IndAndCom == 2 && activate == -1">
        <div class="flex aic jcsb mt40">
          <div class="flex aic flex50">
            <div class="w130 textar mr20 ba80">用户等级:</div>
            <div>
              <el-select v-model="tablist.merchant_level" :disabled="disabled" class="w270" placeholder="请选择">
                <el-option v-for="item in kehudengjiList" :key="item.id" :label="item.data"
                  :value="item.id"></el-option>
              </el-select>
            </div>
          </div>
          <div class="flex aic flex50">
            <div class="w130 textar mr20 ba80">跟进状态:</div>
            <div class="flex60">
              <el-select v-model="tablist.track_status_name" :disabled="disabled" class="w270" placeholder="请选择">
                <el-option v-for="item in genjinzhuangList" :key="item.id" :label="item.data"
                  :value="item.data"></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="flex aic jcsb mt30">
          <div class="flex aic flex50">
            <div class="w130 textar mr20 ba80">是否注册:</div>
            <div>
              <!-- <el-select v-model="tablist.is_merchant" class="w270" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select> -->
              <div v-if="tablist.is_merchant == 1">是</div>
              <div v-else>否</div>
            </div>
          </div>
          <div class="flex aic flex50">
            <div class="w130 textar mr20 ba80">类型:</div>
            <div class="flex60">
              <el-select v-model="tablist.merchant_type_name" :disabled="disabled" class="w270" placeholder="请选择">
                <el-option v-for="item in shanghuleixingList" :key="item.id" :label="item.data"
                  :value="item.data"></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="flex aic jcsb mt30">
          <div class="flex aic flex50">
            <div class="w130 textar mr20 ba80">精准电话:</div>
            <div class="flex60">
              <el-input v-model="tablist.verified_tel" :disabled="disabled" placeholder="请输入内容"></el-input>
            </div>
          </div>
          <div class="flex aic flex50">
            <div class="w130 textar mr20 ba80">微信工作号:</div>
            <div class="flex60">
              <el-input v-model="tablist.wechat" :disabled="disabled" placeholder="请输入内容"></el-input>
            </div>
          </div>
        </div>
        <div class="mt40">
          <div class="flex jcl aic gap10 pt10" v-for="(el, index) in tablist.ext_fields" :key="index">
            <div class="flex aic ">
              <div class="w130 textar mr20 ba80">自定义:</div>
              <div class="mr20">
                <el-select v-model="el.custom_key" :disabled="disabled"   class="w270" placeholder="请选择">
                  <el-option v-for="item in zidingyiList" :key="item.id" :label="item.data"
                    :value="item.custom_key"></el-option>
                </el-select>
              </div>
              <div class="flex aic gap10">
                <div class="">
                  <el-input v-model="el.custom_val"  :disabled="disabled"  placeholder="请输入内容"></el-input>
                </div>
                <div class="cp" @click="delCustom(index)">
                  <i class="el-icon-remove-outline cp"></i>
                </div>
              </div>
            </div>

          </div>
          <div class="flex jcc aic pt10" v-if="!disabled" >
            <el-button type="primary" @click="addExt_fields" size="small">新增字段</el-button>
          </div>
        </div>
        <div class="flex aic jcsb mt40">
          <div class="flex aic flex50">
            <div class="w130 textar mr20 ba80">商家类型:</div>
            <div>
              <el-select v-model="tablist.merchant_cate" :disabled="disabled" class="w270" placeholder="请选择">
                <el-option v-for="item in shangjialeixin" :key="item.id" :label="item.name"
                  :value="item.id"></el-option>
              </el-select>
            </div>
          </div>

        </div>
        <div class="flex aic jcsb mt30">
          <div class="flex aic">
            <div class="w130 textar mr20 ba80">标签:</div>
            <div class="flex jcl aic gap10">

              <el-select class="flex60" v-model="tablist.label_list1" :disabled="disabled" multiple placeholder="请选择"
                @change="bianjiB">
                <el-option v-for="(item, index) in kefubiaoqianList" :key="index" :label="item.data" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="flex aic jcsb mt30">
          <div class="flex aic ">
            <div class="w130 textar mr20 ba80">协助人:</div>
            <div class="flex jcl aic gap10">

              <el-button :disabled='disabled' type="primary" :key="index" v-for="(tag, index) in tablist.assist_list"
                @click="disabled ? '' : handleOpen(tag)">
                {{ tag.nick }}
              </el-button>
              
            </div>
          </div>
        </div>
       
        <div class="flex jcl aic gap10 pt10" >
          <div class="flex aic ">
            <div class="w130 textar mr20 ba80">主营产品:</div>
            <div class="mr20">
              <el-button @click="tips1" :disabled='disabled' type="primary" style="margin-left: 16px;">
                点我打开
              </el-button>
            </div>

          </div>
          <div class="flex jcl aic flexw gap10">

            <el-tag v-for="(tag, index) in checkboxGroup1" closable :key="index" :disable-transitions="false"
              @close="disabled?'':handleClose1(index)">
              {{ tag.data }}
            </el-tag>
          </div>
        </div>
      </div>
      <!-- 商机 -->
      <div v-if="activate == 0">
        <div class="bs1 br6 pd20 bgb mt10">
          <div class="flex jcsb aic pt10 bbo1 pdb10">
            <div class="flex jcl aic pdl10">
              <div class>
                <el-checkbox size="medium" @change="choseAll" v-model="checked">全选</el-checkbox>
              </div>
              <div class="f10 mgl20">已选中{{ followData.length }}个</div>
              <div class="f14 cp mgl20">
                <el-button size="mini " v-if="!closeBtn" type="primary" @click="addshangji">添加</el-button>
              </div>
              <div class="f14 cp mgl20">
                <el-button size="mini " v-if="!closeBtn" type="primary" @click="sjDel">删除</el-button>
              </div>
              <div class="flex jcl aic gap16 f14 mgl20">
                <div>
                  <el-select v-model="search_field" :popper-append-to-body="false" placeholder="请选择搜索条件">
                    <el-option v-for="item in sousuoxuan" :key="item.id" :label="item.name"
                      :value="item.id"></el-option>
                  </el-select>
                </div>
                <div class="w160" v-if="search_field == 'content'">
                  <el-input v-model="search_data" placeholder="请输入内容"></el-input>
                </div>
                <div class="w160" v-if="search_field == 'status'">
                  <el-select v-model="sjgj" class="w160" placeholder="请选择">
                    <el-option v-for="item in shangjigenjinList" :key="item.id" :label="item.data"
                      :value="item.id"></el-option>
                  </el-select>
                </div>

                <div>
                  <el-button type="primary" icon="el-icon-search" @click="search" size="small">搜索</el-button>
                </div>
                <div>
                  <el-button type="primary" @click="resetting()" size="small">重置</el-button>
                </div>
              </div>
            </div>
          </div>
          <div class="pt10 po">
            <el-table :data="tableData" ref="multipleTable" row-key="id" border style="width: 100%" :header-cell-style="{
              'text-align': 'center',
              background: '#E8E8E8',
              color: '#000000',
            }" :cell-style="{ 'text-align': 'center' }">
              <el-table-column fixed prop="checked" label="选中" width="60" type="selection">
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.checked" @change="selectFun(scope.row)"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="120">
                <template slot-scope="scope">
                  <el-button type="primary" @click="handleClick(scope.row)" string="mini">编辑</el-button>
                </template>
              </el-table-column>
              <el-table-column prop="status_name" label="商机跟进状态" width="120"></el-table-column>
              <el-table-column prop="company_name" label="公司名称" width="180"></el-table-column>
              <el-table-column prop="content" label="商机内容" width="180"></el-table-column>
              <el-table-column prop="type_name" label="商机类型" width="180"></el-table-column>
              <el-table-column prop="user_nick" label="跟进人" width="180"></el-table-column>
              <el-table-column prop="department_name" label="所属部门" width="180"></el-table-column>
              <el-table-column prop="create_time" label="创建时间"></el-table-column>
            </el-table>
            <!-- <div class="f16 mt20">总条数(1000)</div> -->
            <!-- 分页 -->
            <div class="pt10 jcc flex">
              <el-pagination background layout="prev, pager, next" :total="total" :pageSize="10" @prev-click="prev"
                @next-click="next" @current-change="current" :current-page="page"></el-pagination>
            </div>
          </div>
        </div>
      </div>
      <!-- 跟进 -->
      <div v-if="activate == 1">
        <div class="bs1 br6 pd20 bgb mt10">
          <div class="pt10 bbo1 pdb10">
            <div class="flex jcl aic pdl10">
              <div class>
                <el-checkbox size="medium" @change="choseAll" v-model="checked">全选</el-checkbox>
              </div>
              <div class="f10 mgl20">已选中{{ followData.length }}个</div>
              <div class="f14 cp mgl20">
                <el-button size="mini " v-if="!closeBtn" type="primary" @click="addgjjl">添加</el-button>
              </div>
              <div class="f14 cp mgl20">
                <el-button size="mini " v-if="!closeBtn" type="primary" @click="gjDel">删除</el-button>
              </div>
              <div class="flex jcl aic gap16 f14 mgl20"></div>
            </div>
            <div class="flex jcl aic gap8 mt20">
              <div class="jubude flex jcl aic gap8">
                <div class="w70 textar f14 o80 f12">跟进时间:</div>
                <div>
                  <el-date-picker v-model="begin_track_time" type="date" @change="handleChange1"
                    format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                  <i class="pdr10 pdl10 gray f12">To</i>

                  <el-date-picker v-model="end_track_time" type="date" format="yyyy 年 MM 月 dd 日"
                    value-format="yyyy-MM-dd" @change="handleChange2" placeholder="选择日期"></el-date-picker>
                </div>
              </div>
              <div class="w150">
                <el-select v-model="search_field" :popper-append-to-body="false" placeholder="请选择搜索条件">
                  <el-option v-for="item in sousuoxuan1" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </div>
              <div class="w160" v-if="search_field == 'content'">
                <el-input v-model="search_data" placeholder="请输入内容"></el-input>
              </div>
              <div class="w160" v-if="search_field == 'wechat'">
                <el-input v-model="search_data" placeholder="请输入内容"></el-input>
              </div>
              <div class="w160" v-if="search_field == 'track_status'">
                <el-select v-model="sjgj" class="w160" placeholder="请选择">
                  <el-option v-for="item in genjinztList" :key="item.key" :label="item.value"
                    :value="item.key"></el-option>
                </el-select>
              </div>

              <div>
                <el-button type="primary" icon="el-icon-search" @click="search1" size="small">搜索</el-button>
              </div>
              <div>
                <el-button type="primary" @click="resetting1()" size="small">重置</el-button>
              </div>
            </div>
          </div>
          <div class="pt10 po">
            <el-table :data="tableData" ref="multipleTable" row-key="id" border style="width: 100%" :header-cell-style="{
              'text-align': 'center',
              background: '#E8E8E8',
              color: '#000000',
            }" :cell-style="{ 'text-align': 'center' }">
              <el-table-column fixed prop="checked" label="选中" width="60" type="selection">
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.checked" @change="selectFun(scope.row)"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="120">
                <template slot-scope="scope">
                  <el-button v-if="!closeBtn" type="primary" @click="handleClick1(scope.row)"
                    string="mini">编辑</el-button>
                </template>
              </el-table-column>
              <el-table-column prop="company_name" label="跟进企业" width="120"></el-table-column>
              <el-table-column prop="merchant_level_name" label="用户等级" width="180"></el-table-column>
              <el-table-column prop="track_status_name" label="跟进状态" width="180"></el-table-column>
              <el-table-column prop="content" label="跟进内容" width="180"></el-table-column>
              <el-table-column prop="wechat" label="微信号" width="120"></el-table-column>
              <el-table-column prop="user_nick" label="跟进人" width="180"></el-table-column>
              <el-table-column prop="department_name" label="所属部门" width="180"></el-table-column>
              <el-table-column prop="track_time" label="跟进时间"></el-table-column>
            </el-table>
            <!-- <div class="f16 mt20">总条数(1000)</div> -->
            <!-- 分页 -->
            <div class="pt10 jcc flex">
              <el-pagination background layout="prev, pager, next" :total="total" :pageSize="10" @prev-click="prev"
                @next-click="next" @current-change="current" :current-page="page"></el-pagination>
            </div>
          </div>
        </div>
      </div>
      <!-- 编辑弹窗 -->
      <div>
        <pop v-show="woshibianji">
          <div class="zindex w700 pr">
            <div class="flex jcr aic pa wtrp">
              <i class="el-icon-close f18 cp" @click="bjclose()"></i>
            </div>
            <div>
              <div class="borbb ba80 f16">{{ titleS }}</div>
            </div>
            <div>
              <div class="f14 ba80 redB mt20 mgb10">{{ titleS }}</div>
              <div>
                <el-input type="textarea" placeholder="请输入内容" v-model="textarea" maxlength="1000" show-word-limit
                  rows="6"></el-input>
              </div>
            </div>
            <div>
              <div class="flex aic jcsb mt40">
                <div class="flex aic flex50">
                  <div class="w130 textar mr20 ba80">跟进主体:</div>
                  <div>{{ tablist.company_name }}</div>
                </div>
                <div class="flex aic flex50" v-if="titleS == '商机记录'">
                  <div class="w130 textar mr20 ba80">商机类型:</div>
                  <div class="flex60">
                    <el-select v-model="sjlx" class="w270" placeholder="请选择">
                      <el-option v-for="item in shangjileixingList" :key="item.id" :label="item.data"
                        :value="item.id"></el-option>
                    </el-select>
                  </div>
                </div>
                <div class="flex aic flex50" v-if="titleS == '跟进记录'">
                  <div class="w130 textar mr20 ba80">用户等级:</div>
                  <div class="flex60">
                    <el-select v-model="genjindengji" class="w270" placeholder="请选择">
                      <el-option v-for="item in kehudengjiList" :key="item.id" :label="item.data"
                        :value="item.id"></el-option>
                    </el-select>
                  </div>
                </div>
              </div>
              <div class="flex aic jcsb mt40">
                <div class="flex aic flex50">
                  <div class="w130 textar mr20 ba80">跟进人:</div>
                  <div>{{ tablist.user_nick }}</div>
                </div>
                <div class="flex  flex50" v-if="titleS == '商机记录'">
                  <div class="w130 textar mr20 ba80 pt10">商机跟进状态:</div>
                  <div class="flex60">

                    <el-select v-model="sjzt" class="w270" placeholder="请选择">
                      <el-option v-for="item in shangjigenjinList" :key="item.id" :label="item.data"
                        :value="item.id"></el-option>
                    </el-select>

                  </div>
                </div>
                <div class="flex aic flex50" v-if="titleS == '跟进记录'">
                  <div class="w130 textar mr20 ba80">跟进状态:</div>
                  <div class="flex60">
                    <el-select v-model="tablist.track_status" class="w270" placeholder="请选择">
                      <el-option v-for="item in genjinzhuangList" :key="item.id" :label="item.data"
                        :value="item.id"></el-option>
                    </el-select>
                    <div class="pt10">
                      <el-button type="primary" size="mini" @click="goto('SetUp', 3)">跟进状态管理</el-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex aic jcsb mt40">
                <div class="flex aic flex50" v-if="titleS == '跟进记录'">
                  <div class="w130 textar mr20 ba80">跟进时间:</div>
                  <div class="flex60">
                    <el-date-picker v-model="track_time" value-format="yyyy-MM-dd" type="date"
                      placeholder="选择日期"></el-date-picker>
                  </div>
                </div>
                <div class="flex aic flex50" v-if="titleS == '跟进记录'">
                  <div class="w130 textar mr20 ba80">微信工作号:</div>
                  <div class="flex60">
                    <el-input v-model="wechat" placeholder="请输入内容"></el-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt40">
          <div class="flex jcl aic gap10 pt10" v-for="(el, index) in tablist.ext_fields" :key="index">
            <div class="flex aic ">
              <div class="w130 textar mr20 ba80">自定义:</div>
              <div class="mr20">
                <el-select v-model="el.custom_key"  class="w270" placeholder="请选择">
                  <el-option v-for="item in zidingyiList" :key="item.id" :label="item.data"
                    :value="item.custom_key"></el-option>
                </el-select>
              </div>
              <div class="flex aic gap10">
                <div class="">
                  <el-input v-model="el.custom_val"  placeholder="请输入内容"></el-input>
                </div>
                <div class="cp" @click="delCustom(index)">
                  <i class="el-icon-remove-outline cp"></i>
                </div>
              </div>
            </div>

          </div>
          <div class="flex jcc aic pt10" >
            <el-button type="primary" @click="addExt_fields" size="small">新增字段</el-button>
          </div>
        </div>
            <div class="jcc aic flex pt20" v-if="titleS == '商机记录'">
              <el-button type="primary" @click="addsj1">提交</el-button>
            </div>
            <div class="jcc aic flex pt20" v-if="titleS == '跟进记录'">
              <el-button type="primary" @click="addgj1">提交</el-button>
            </div>
          </div>
        </pop>
      </div>
      <!-- 联系人管理弹窗 -->
      <div>
        <pop v-show="woshilianxiren">
          <div class="zindex w700 pr">
            <div class="flex jcr aic pa wtrp">
              <i class="el-icon-close f18 cp" @click="lxrclose()"></i>
            </div>
            <div>
              <div class="ba80 f24 textac">联系人管理</div>
            </div>
            <div class="bghhh ovh mt40">
              <div class="flex aic jcsb mt20">
                <div class="flex aic flex50">
                  <div class="w130 textar mr20 ba80 redB">联系人:</div>
                  <div class="flex60">
                    <el-input v-model="Iname" placeholder="请输入内容"></el-input>
                  </div>
                </div>
                <div class="flex aic flex50">
                  <div class="w130 textar mr20 ba80 redB">联系电话:</div>
                  <div class="flex60">
                    <el-input v-model="Iphone" placeholder="请输入内容"></el-input>
                  </div>
                </div>
              </div>
              <div class="flex aic jcsb mt20">
                <div class="flex aic flex50">
                  <div class="w130 textar mr20 ba80 redB">岗位:</div>
                  <div class="flex60">
                    <el-input v-model="Ititle" placeholder="请输入内容"></el-input>
                  </div>
                </div>
                <div class="flex aic flex50">
                  <div class="w130 textar mr20 ba80 redB">类型:</div>
                  <div class="flex60">
                    <el-radio v-model="Iradio" label="0">默认</el-radio>
                    <el-radio v-model="Iradio" label="1">精准电话</el-radio>
                    <el-radio v-model="Iradio" label="2">注册电话</el-radio>
                  </div>
                </div>
              </div>
              <div>
                <div class="textac mt40" v-if="!closeBtn">
                  <el-button size="mini " type="primary" @click="saveCall">保存</el-button>
                </div>
              </div>
            </div>

            <div class="pt20 po">
              <el-table :data="tableData" ref="multipleTable" row-key="id" border style="width: 100%"
                :header-cell-style="{
                  'text-align': 'center',
                  background: '#E8E8E8',
                  color: '#000000',
                }" :cell-style="{ 'text-align': 'center' }">
                <el-table-column fixed prop="checked" label="选中" width="60" type="selection">
                  <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.checked" @change="selectFun(scope.row)"></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="140">
                  <template slot-scope="scope">
                    <el-button type="primary" @click="handleClick2(scope.row)" string="mini" v-if="!closeBtn"
                      size="mini">编辑</el-button>
                    <el-button type="primary" @click="handleClick3(scope.row)" v-if="!closeBtn" string="mini"
                      size="mini">删除</el-button>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="联系人" width="120"></el-table-column>
                <el-table-column prop="phone" label="联系电话" width="140"></el-table-column>
                <el-table-column prop="title" label="岗位" width="140"></el-table-column>
                <el-table-column prop="type" label="类型" width="120">
                  <template slot-scope="scope">
                    <div v-if="scope.row.type == 2">
                      <div>注册电话</div>
                    </div>
                    <div v-if="scope.row.type == 1">
                      <div>精准电话</div>
                    </div>
                    <div v-if="scope.row.type == 0">
                      <div>默认</div>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column prop="create_time" label="操作时间"></el-table-column>
              </el-table>
              <!-- 分页 -->
              <div class="pt10 jcc flex">
                <el-pagination background layout="prev, pager, next" :total="total" :pageSize="10" @prev-click="prev"
                  @next-click="next" @current-change="current" :current-page="page"></el-pagination>
              </div>
            </div>
          </div>
        </pop>
      </div>
    </div>
    <MemberListPop ref="MLP"> </MemberListPop>
    <el-drawer title="" :modal="false" :visible.sync="drawer" :direction="direction" :before-close="handleClose"
      size="50%">
      <div class="w650s ova">
        <div class="pd10">
          <el-input v-model="SCinput" @input="SCscreent" placeholder="搜索"></el-input>
        </div>

        <div slot="reference">
          <ul class="flex jcc aic flexw gap16">
            <li class="h60s1 br4 flex jcc aic cp  bvvo1" :class="item.isChecked ? 'bgred white' : 'black'"
              v-for="item in filteredItems" :key="item.id" @click="choseT(item)">
              <i> {{ item.data }}</i>
            </li>
          </ul>
        </div>
        <div class="flex jcc aic pt10 gap16">
          <div>
            <el-button type="success" @click="success12">确定</el-button>
          </div>
          <div>

            <el-button type="danger" @click="handleClose">取消</el-button>

          </div>
        </div>
      </div>



    </el-drawer>
  </pop>
</template>

<script>
export default {
  name: "OpenSeaDataPopup",
  props: ["closeBtn"],
  data() {
    let that = this;
    return {
      radio: "1",
      textarea: "", //商机记录
      woshibianji: false,
      woshilianxiren: false,
      dialogVisible: false,
      tableData: [], //列表数据
      activate: -1, //tab
      IndAndCom: 1,
      status: "", //
      dynamicTags: ["标签一"],
      inputVisible: false,
      inputValue: "",
      options: [

      ],
      value: "",
      isTrue: false,
      page: 1,
      size: "",
      form: {}, //搜索
      followData: [], //选中
      checked: false, //全选
      input: "",
      isOpenShow: false,
      value1: "",
      allPage: 0,
      total: 0,
      tablist: {
        main_products_ids:[],
        ext_fields:[{custom_key:'',custom_val:''}]
      },
      kehudengji: "",
      genjinzhuangtai: "",
      kehudengjiList: [],
      genjinzhuangList: [],
      from_type: 1,
      choseAID: "",
      delMSG: "",
      isDel: false,
      DelList: [],
      DelListNum: 0,
      isAllT: false,
      assist_ids: [],
      shanghuleixing: "",
      shanghuleixingList: [],
      kefubiaoqianList: [],
      CrmOptionsList: [],
      to_department_id: "",
      options1: [],
      qiyeguimoList: [],
      dengjizhuangtaiList: [],
      search_field: "",
      search_data: "",
      props: {
        lazy: true,
        checkStrictly: false,
        lazyLoad: (node, resolve) => {
          const { level } = node; // node 节点数据
          const nodes = []; // 动态节点
          let type = level == 0 ? "0" : node.value; // 0 代表第一次请求
          that
            .provinceFn(type, level)
            .then((res) => {
              // console.log(res);
              res.list.map((item) => {
                let area = {
                  value: item.id,
                  label: item.name,
                  leaf: node.level >= 2,
                };
                nodes.push(area);
              });
              resolve(nodes); // 回调
            })
            .catch((error) => {
              console.log(error);
            });
        },
      },
      // props: {
      //   multiple: true,
      //   checkStrictly: true,
      //   children: 'children',
      //   label: "nick",
      //   value: "id",
      // },
      company_id: 0,
      company_id1: 0,
      options2: [],
      shangjileixingList: [],
      sjlx: "",
      sjzt: "",
      sousuoxuan: [
        { name: "商机内容", id: "content" },
        // { name: "跟进人", id: "type" },
        { name: "商机跟进状态", id: "status" },
      ],
      sousuoxuan1: [
        { name: "跟进内容", id: "content" },
        { name: "微信工作号", id: "wechat" },
        { name: "跟进状态", id: "track_status" },
      ],
      sjgj: "",
      begin_track_time: "",
      end_track_time: "",
      titleS: "",
      genjinztList: [],
      gjzt: "",
      isEdit1: false,
      track_time: '',
      genjindengji: "",
      wechat: "",
      Iname: "",
      Iphone: "",
      lianxirenID: "",
      Ititle: "",
      Iradio: "",
      disabled: true,
      followUpList: [],
      followUper: '',
      shangjigenjinList: [],
      pensonList: [],
      checkboxGroup1: [],
      drawer: false,

      SCinput: '',
      direction: 'rtl',
      cities: [],

      date: {},
      shangjialeixin: [{
        name: '企业用户', id: 2
      },
      {
        name: '个人用户', id: 1
      },
      {
        name: '未知', id: 0
      }],
      SCscreent:'',
      zidingyiList:[],
      arr:[],
    };
  },
  mounted() {
    this.isTrue = true;
    this.Department();
    this.qiyeguimo();
    this.dengjizhuangtai();
    this.CrmOptions();
    this.shangjileixing();
    this.genjinzt();
    this.followUp()
    this.shangjigenjin()
    this.xiezhuren()
    this.zhuyingchanping()
    this.zidingyi()
    this.track_time = new Date().toISOString().substr(0, 10)

  },

  methods: {
    lxrclose() {
      this.woshilianxiren = false;
    },
    bjclose() {
      this.woshibianji = false;
    },
    // 关闭弹窗
    handleOpen(done) {
      this.$root.$off("fenpeis");
      this.$root.$on("fenpeis", this.fenpeiRen);
      this.$refs.MLP.OPenShow();
      this.$refs.MLP.chosephantomT();
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      //     done();
      //   })
      //   .catch((_) => {});
    },
    fenpeiRen(e) {
      this.bianjiC(e)
      // console.log(e)
    },
    //获取部门
    async Department() {
      let data = {
        power: 1, //角色：-1=全部 0无 1crm
        name: "", //名称
      };
      await this.$axios
        .get(this.webAPI + "/AdminDepartment/index", {
          params: data,
        })
        .then((res) => {
          if (res.code == 200) {
            // 请求成功，处理响应数据
            // console.log(res.data.list)
            let data1 = {
              id: 0,
              name: "未分组",
            };
            res.data.list.unshift(data1);
            this.options1 = res.data.list;

            // this.department_id = this.options1[0].id;

          }
        });
    },


    //全选
    choseAll() {
      if (this.checked == false) {
        this.tableData.forEach((item) => {
          item.checked = false;
        });
        this.followData = [];
      } else {
        this.tableData.forEach((item) => {
          item.checked = true;
        });

        this.followData = JSON.parse(JSON.stringify(this.tableData));
      }
    },
    // tab切换
    chose(v) {
      this.activate = v;
      this.status = v;
      this.followData = [];
      this.page = 1
      if (v == 0) {
        this.resetting()
      }
      if (v == 1) {
        this.resetting1()
      }

    },
    choseOne(){
      this.activate=-1
      this.disabled=true
      this.IndAndCom =1
    },

    // tab切换
    chose2(v) {
      this.IndAndCom = v;
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    //上一页
    prev(v) {
      this.followData = [];
      this.checked = false;
      this.page = v;
      if (this.activate == 0) {
        this.shangji();
      }
      if (this.activate == 1) {
        this.CrmTrackList();
      }
      if (this.activate == -1) {
        this.CrmPoolDetail();
      }


    },
    //下一页
    next(v) {
      this.followData = [];
      this.checked = false;
      this.page = v;
      if (this.activate == 0) {
        this.shangji();
      }
      if (this.activate == 1) {
        this.CrmTrackList();
      }
      if (this.activate == -1) {
        this.CrmPoolDetail();
      }
    },
    //选中页
    current(v) {
      this.followData = [];
      this.checked = false;
      this.page = v;
      if (this.activate == 0) {
        this.shangji();
      }
      if (this.activate == 1) {
        this.CrmTrackList();
      }
      if (this.activate == -1) {
        this.CrmPoolDetail();
      }
    },
    CloseShow() {
      this.isOpenShow = false;
      this.$root.$emit('upData', true)
    },
    OPenShow() {
      this.isOpenShow = true;
      this.$root.$off('getEID')
      this.$root.$on("getEID", this.CrmPoolDetail);

    },
    async CrmPoolDetail(e) {
      // if (e > 0) {
      if (e) {
        this.company_id = e;
      }

      let data = {
        id: this.company_id,
      };
      await this.$axios
        .get(this.webAPI + "/CrmPoolDetail/read", { params: data })
        .then((res) => {
          if (res.code == "200") {
            let that = this;
            if(res.data.main_products_names)
            {res.data.main_products_names.forEach(i=>{
              i.data=i.name
            })
            this.checkboxGroup1=res.data.main_products_names
          
          }
           
            this.tablist = res.data;

            let arr = [];
            this.tablist.label_list.forEach((i) => {
              arr.push(i.id * 1);
            });
            this.tablist.label_list1 = arr;

            let arr1 = [];
            this.tablist.assist_list.forEach((i) => {
              arr1.push(i.nick);
            });

            this.tablist.assist_list1 = arr1;
            that.tablist.value = [
              res.data.province_name,
              res.data.city_name,
              res.data.district_name,
            ];

          } else {
            // this.info(res.msg);
          }
        });
      // } else {
      //   this.disabled = false
      // }


    },
    async CrmOptions() {
      await this.$axios.get(this.webAPI + "/CrmOptions/index").then((res) => {
        if (res.msg == "操作成功") {
          res.data.list.forEach((i, index) => {
            if (i.id == 4) {
              this.kehudengji = i.code;
              this.kehudeng();
            }
            if (i.id == 3) {
              this.genjinzhuangtai = i.code;
              this.genjinzhuang();
            }
            if (i.id == 1) {
              this.shanghuleixing = i.code;
              this.shanghu();
            }
            if (i.id == 8) {
              this.kefubiaoqian = i.code;
              this.biaoqian();
            }
          });

          // console.log(res.data.list);
          // this.success("操作成功");
        } else {
          // this.info(res.msg);
        }
      });
    },
    async genjinzhuang() {
      let data = {
        code: this.genjinzhuangtai,
        status: 1,
      };
      await this.$axios
        .get(this.webAPI + "/CrmOptionsList/index", { params: data })
        .then((res) => {
          if (res.msg == "操作成功") {
            this.genjinzhuangList = res.data.list;

          } else {
          }
        });
    },
    async kehudeng() {
      let data = {
        code: this.kehudengji,
        status: 1,
      };
      await this.$axios
        .get(this.webAPI + "/CrmOptionsList/index", { params: data })
        .then((res) => {
          if (res.msg == "操作成功") {
            this.kehudengjiList = res.data.list;
          } else {
          }
        });
    },
    async shanghu() {
      let data = {
        code: this.shanghuleixing,
        status: 1,
      };
      await this.$axios
        .get(this.webAPI + "/CrmOptionsList/index", { params: data })
        .then((res) => {
          if (res.msg == "操作成功") {
            // console.log(res)
            this.shanghuleixingList = res.data.list;
            // this.genjinzhuangList = res.data.list;
          } else {
          }
        });
    },
    async biaoqian() {
      let data = {
        code: this.kefubiaoqian,
        status: 1,
      };
      await this.$axios
        .get(this.webAPI + "/CrmOptionsList/index", { params: data })
        .then((res) => {
          if (res.msg == "操作成功") {
            this.kefubiaoqianList = res.data.list;
            // console.log( this.kefubiaoqianList,'biaoq')
            // this.genjinzhuangList = res.data.list;
          } else {
          }
        });
    },
    async shangjileixing() {
      let data = {
        code: "BUSINESS_OPPORTUNITIES_TYPE",
        status: 1,
      };
      await this.$axios
        .get(this.webAPI + "/CrmOptionsList/index", { params: data })
        .then((res) => {
          if (res.msg == "操作成功") {
            this.shangjileixingList = res.data.list;

            // this.genjinzhuangList = res.data.list;
          } else {
          }
        });
    },
    async shangjigenjin() {
      let data = {
        code: "BUSINESS_OPPORTUNITIES_STATUS",
        status: 1,
      };
      await this.$axios
        .get(this.webAPI + "/CrmOptionsList/index", { params: data })
        .then((res) => {
          if (res.msg == "操作成功") {
            this.shangjigenjinList = res.data.list;

            // this.genjinzhuangList = res.data.list;
          } else {
          }
        });
    },
    async qiyeguimo() {
      let data = {
        code: "D1003",
      };
      await this.$axios
        .get(this.webAPI + "/CrmComplex/read", { params: data })
        .then((res) => {
          if (res.msg == "操作成功") {
            this.qiyeguimoList = res.data.list;

          } else {
          }
        });
    },
    async genjinzt() {
      let data = {
        code: "D1005",
      };
      await this.$axios
        .get(this.webAPI + "/CrmComplex/read", { params: data })
        .then((res) => {
          if (res.msg == "操作成功") {
            this.genjinztList = res.data.list;

          } else {
          }
        });
    },
    async xiezhuren() {
      let data = {
        pid: -1,
        department_id: this.tablist.department_id,
        position_id: 0
      }


      await this.$axios
        .get(this.webAPI + "/AdminUser/index", {
          params: data
        })
        .then(res => {
          // 请求成功，处理响应数据

          this.pensonList = res.data.list
        });
    },

    async dengjizhuangtai() {
      let data = {
        code: "D1002",
      };
      await this.$axios
        .get(this.webAPI + "/CrmComplex/read", { params: data })
        .then((res) => {
          if (res.msg == "操作成功") {
            this.dengjizhuangtaiList = res.data.list;

          } else {
          }
        });
    },
    handleChange4(v) {
     this.arr= this.$refs.cascader.getCheckedNodes()[0].pathLabels.join(",").split(",");
      // console.log(v, "province_name");
    },
    //省市区都是这一个
    async provinceFn(v, i) {
      // this.date.pid = v;

      // let data = {};
      // if (i == 0) {
      //   data.region_field = "province_name";
      // } else if (i == 1) {
      //   this.province_name = "v";
      //   data.region_field = "province_name";
      //   data.region_val = v;
      // } else {
      //   data.region_field = "city_name";
      //   data.region_val = v;
      // }
      // const res = await this.$axios.get(
      //   this.webAPI + "/CrmImportPoolEnum/region",
      //   {
      //     params: data,
      //   }
      // );
      // return res.data;
      this.date.pid = v;
      const res = await this.$axios.get(
        this.webAPI + "/Region/getChildrenById",
        {
          params: this.date
        }
      );
      return res.data;
    },
    search() {
      this.shangji();
    },
    search1() {
      this.CrmTrackList();
    },
    async shangji() {
      let data = {
        company_id: this.company_id,
        search_field: this.search_field,
        search_data: this.search_data,
        page: this.page,
        size: 10,
      };
      if (data.search_field == "status") {
        data.search_data = this.sjgj;
      }
      if (data.search_field == "type") {
        data.search_data = this.followUper;
      }
      if (!this.closeBtn) {
        data.from_type = 1
      } else {
        data.from_type = 0
      }
      await this.$axios
        .get(this.webAPI + "/CrmBusinessOpportunitiesList/company", {
          params: data,
        })
        .then((res) => {
          if (res.msg == "操作成功") {
            res.data.data.forEach((i) => {
              i.checked = false;
            });
            // this.shangji()
            this.total = res.data.total
            this.tableData = res.data.data;

          } else {
          }
        });
    },
    addshangji() {
      this.titleS = "商机记录";
      this.isEdit = false;
      this.textarea = "";
      this.sjlx = "";
      this.sjzt = "";
      this.woshibianji = true;
    },
    async addsj() {
      let data = {
        company_id: this.company_id,
        content: this.textarea,
        type: this.sjlx,
        status: this.sjzt,
      };
      await this.$axios
        .post(this.webAPI + "/CrmBusinessOpportunitiesList/add", data)
        .then((res) => {
          if (res.msg == "操作成功") {
            this.success("成功");
            this.shangji();
            this.woshibianji = false;

          } else {
            this.info(res.msg)
          }
        });
    },
    addsj1() {
      if (this.isEdit) {
        this.editsj();
      } else {
        this.addsj();
      }
    },
    goto(text, id) {
      if (!id) {
        this.$router.push({
          name: text,
        });
      } else {
        this.$router.push({
          name: text,
          query: {
            id: id,
          },
        });
      }
    },
    //成功提示
    success(v) {
      this.$message({
        type: "success",
        message: v,
      });
    },
    choseT(e) {
      e.isChecked = !e.isChecked;
    },

    info(v) {
      this.$message({
        type: "error",
        message: v,
      });
    },
    handleClick(e) {
      // console.log(e)
      this.addshangji();
      this.textarea = e.content;
      this.sjlx = e.type;
      this.sjzt = e.status;
      this.company_id1 = e.id;
      this.isEdit = true;
    },
    handleClick1(e) {
      // console.log(e);
      this.addgjjl();
      this.textarea = e.content;
      this.wechat = e.wechat;
      this.company_id1 = e.id;
      this.isEdit1 = true;

      if (e.track_time) {
        this.track_time = e.track_time;
      } else {

        this.track_time = new Date().toISOString().substr(0, 10)

      }

      this.genjindengji = e.merchant_level;
    },
    handleClick2(e) {
      this.Iname = e.name;
      this.Iphone = e.phone;
      this.lianxirenID = e.id;
      this.Ititle = e.title;
      this.Iradio = e.type.toString();
    },
    handleClick3(e) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.lianxirenID = e.id;
          this.CrmContactsDel();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async editsj() {
      let data = {
        id: this.company_id1,
        content: this.textarea,
        type: this.sjlx,
        status: this.sjzt,
      };
      await this.$axios
        .post(this.webAPI + "/CrmBusinessOpportunitiesList/edit", data)
        .then((res) => {
          if (res.msg == "操作成功") {
            this.success("成功");
            this.shangji();
            this.woshibianji = false;

          } else {
            this.info(res.msg)
          }
        });
    },
    selectFun(e) {
      // console.log(e)
      this.followData = [];
      this.tableData.forEach((item) => {
        if (item.checked) {
          this.followData.push(item);
        }
      });
      if (this.followData.length == this.tableData.length) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },
    async sjDel() {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.sjDel1();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async sjDel1() {
      let arr = [];
      this.followData.forEach((i) => {
        arr.push(i.id);
      });
      let data = {
        company_id: this.company_id,
        ids: arr,
        is_select_all: 0,
      };
      await this.$axios
        .post(this.webAPI + "/CrmBusinessOpportunitiesList/del", data)
        .then((res) => {
          if (res.msg == "操作成功") {
            this.success("成功");
            this.shangji();

          } else {
            this.info(res.msg)
          }
        });
    },
    resetting() {
      this.search_field = "";
      this.search_data = "";
      this.sjgj = "";
      this.shangji();
    },
    resetting1() {
      this.search_field = "";
      this.search_data = "";
      this.sjgj = "";
      this.begin_track_time = "";
      this.end_track_time = "";
      this.CrmTrackList();
    },
    handleChange1(e) {
      console.log(e);
    },
    handleChange2(e) {
      console.log(e);
    },
    async CrmTrackList() {
      let data = {
        company_id: this.company_id,
        search_field: this.search_field,
        search_data: this.search_data,
        page: this.page,
        size: 10,
      };

      if (this.search_field == "track_status") {
        data.search_data = this.sjgj;
      }
      if (this.begin_track_time) {
        data.begin_track_time = this.begin_track_time;
      }
      if (this.end_track_time) {
        data.end_track_time = this.end_track_time;
      }
      if (!this.closeBtn) {
        data.from_type = 1
      } else {
        data.from_type = 0
      }
      await this.$axios
        .get(this.webAPI + "/CrmTrackList/company", {
          params: data,
        })
        .then((res) => {
          if (res.msg == "操作成功") {
            res.data.data.forEach((i) => {
              i.checked = false;
            });
            // this.shangji()
            this.tableData = res.data.data;

          } else {
            this.info(res.msg)
          }
        });
    },
    addgjjl() {
      this.titleS = "跟进记录";
      this.isEdit1 = false;
      this.textarea = "";
      this.track_time = new Date().toISOString().substr(0, 10);
      this.genjindengji = "";
      this.woshibianji = true;
    },
    async CrmTrackListadd() {
      let data = {
        company_id: this.company_id,
        content: this.textarea,
        track_time: this.track_time,
      };

      await this.$axios
        .post(this.webAPI + "/CrmTrackList/add", data)
        .then((res) => {
          if (res.msg == "操作成功") {
            this.CrmTrackList();
            this.woshibianji = false;
            // this.shangji()
            // this.tableData = res.data.data;

          } else {
            this.info(res.msg)
          }
        });
    },
    async CrmTrackListedit() {
      let data = {
        id: this.company_id1,
        content: this.textarea,
        track_time: this.track_time,
      };

      await this.$axios
        .post(this.webAPI + "/CrmTrackList/edit", data)
        .then((res) => {
          if (res.msg == "操作成功") {
            this.CrmTrackList();
            this.company_id1 = "";
            this.woshibianji = false;
            // this.shangji()
            // this.tableData = res.data.data;

          } else {
            this.info(res.msg)
          }
        });
    },
    addgj1() {
      if (this.isEdit1) {
        this.CrmTrackListedit();
        this.bianjiA();
      } else {
        this.bianjiA();
        this.CrmTrackListadd();
      }
    },
    async bianjiA() {
      let data = {
        id: this.company_id,
      };
      if (this.genjindengji) {
        data.merchant_level = this.genjindengji;
      }
      if (this.tablist.track_status) {
        data.track_status = this.tablist.track_status;
      }

      if (this.wechat) {
        data.wechat = this.wechat;
      }
      if(this.tablist.ext_fields){
        data.ext_fields=this.tablist.ext_fields
      }
      await this.$axios
        .post(this.webAPI + "/CrmPoolDetail/edit", data)
        .then((res) => {
          if (res.msg == "操作成功") {
            this.CrmTrackList();
          } else {
            this.info(res.msg)
            // this.info(res.msg);
          }
        });
    },
    async bianjiB() {
      let data = {
        id: this.company_id,
      };

      if (this.tablist.label_list1.length > 0) {
        data.label_ids = this.tablist.label_list1;
      } else {
        data.label_ids = ['']
      }
      await this.$axios
        .post(this.webAPI + "/CrmPoolDetail/edit", data)
        .then((res) => {
          if (res.msg == "操作成功") {
            this.CrmTrackList();
          } else {
            this.info(res.msg)
            // this.info(res.msg);
          }
        });
    },
    async bianjiC(e) {
      let data = {
        id: this.company_id,
      };
      if (e.length > 0) {
        data.assist_ids = e;
      } else {
        data.assist_ids = ['']
      }

      await this.$axios
        .post(this.webAPI + "/CrmPoolDetail/edit", data)
        .then((res) => {
          if (res.msg == "操作成功") {
            this.CrmPoolDetail();
          } else {
            this.info(res.msg)
            // this.info(res.msg);
          }
        });
    },
    async CrmTrackListDel() {
      let arr = [];
      this.followData.forEach((i) => {
        arr.push(i.id);
      });
      let data = {
        company_id: this.company_id,
        ids: arr,
        is_select_all: 0,
      };

      await this.$axios
        .post(this.webAPI + "/CrmTrackList/del", data)
        .then((res) => {
          if (res.msg == "操作成功") {
            this.success("删除成功");
            this.CrmTrackList();
          } else {
            this.info(res.msg);
          }
        });
    },
    gjDel() {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.CrmTrackListDel();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    openlianxiren() {
      this.woshilianxiren = true;
      this.CrmContactsList();
    },
    async CrmContactsList() {
      let data = {
        company_id: this.company_id,
        page: this.page,
        size: 10,
      };

      await this.$axios
        .get(this.webAPI + "/CrmContacts/index", {
          params: data,
        })
        .then((res) => {
          if (res.msg == "操作成功") {
            res.data.data.forEach((i) => {
              i.checked = false;
            });
            // this.shangji()
            this.tableData = res.data.data;

          } else {
            this.info(res.msg)
          }
        });
    },
    saveCall() {
      if (this.lianxirenID) {
        this.CrmContactsEdit();
      } else {
        this.CrmContactsadd();
      }
    },
    clearII() {
      this.Iname = ''
      this.Iphone = ''
      this.Ititle = ''
      this.Iradio = ''
    },
    async CrmContactsadd() {
    if(this.company_id&&this.Iname&&this.Iphone&& this.Ititle&&this.Iradio){

 
      let data = {
        company_id: this.company_id,
        name: this.Iname,
        phone: this.Iphone,
        title: this.Ititle,
        type: this.Iradio,
      };

      await this.$axios
        .post(this.webAPI + "/CrmContacts/add", data)
        .then((res) => {
          if (res.msg == "操作成功") {
            this.clearII()
            this.success("操作成功");
            this.CrmContactsList();
          } else {
            this.info(res.msg);
          }
        });
      }else{
      this.info('请填写完整')
    }
    },
    tips1(e) {
        this.SCinput=''
      this.drawer = true
      this.zhuyingchanping()

    },
    async CrmContactsEdit() {
      let data = {
        id: this.lianxirenID,
        name: this.Iname,
        phone: this.Iphone,
        title: this.Ititle,
        type: this.Iradio,
      };

      await this.$axios
        .post(this.webAPI + "/CrmContacts/edit", data)
        .then((res) => {
          if (res.msg == "操作成功") {
            this.success("操作成功");
            this.lianxirenID = "";
            this.clearII()
            this.CrmContactsList();
            this.disabled = true
          } else {
            this.info(res.msg);
          }
        });
    },
    async CrmContactsDel() {
      let data = {
        id: this.lianxirenID,
      };
      await this.$axios
        .post(this.webAPI + "/CrmContacts/del", data)
        .then((res) => {
          if (res.msg == "操作成功") {
            this.success("操作成功");
            this.CrmContactsList();
          } else {
            this.info(res.msg);
          }
        });
    },
    isChangeList() {
      this.disabled = false;
    },
    async saveList() {
      this.disabled = true;
      let data = {
        id: this.company_id,
      };
      if (this.tablist.company_name) {
        data.company_name = this.tablist.company_name;
      }
      if (this.tablist.legal_representative) {
        data.legal_representative = this.tablist.legal_representative;
      }
      if (this.tablist.company_status) {
        data.company_status = this.tablist.company_status;
      }
      if (this.tablist.registered_capital) {
        data.registered_capital = this.tablist.registered_capital;
      }
      if (this.tablist.establishment_date) {
        data.establishment_date = this.tablist.establishment_date;
      }
      if (this.tablist.license_no) {
        data.license_no = this.tablist.license_no;
      }
      if (this.tablist.tel) {
        data.tel = this.tablist.tel;
      }
      if (this.tablist.more_tel) {
        data.more_tel = this.tablist.more_tel;
      }
      if (this.tablist.email) {
        data.email = this.tablist.email;
      }
      if (this.tablist.more_email) {
        data.more_email = this.tablist.more_email;
      }
      if (this.tablist.more_email) {
        data.more_email = this.tablist.more_email;
      }
      // if (this.tablist.verified_tel) {
      //   data.verified_tel = this.tablist.verified_tel;
      // }
      if (this.tablist.addr) {
        data.addr = this.tablist.addr;
      }
      if (this.tablist.province_name) {
        data.province_name = this.tablist.province_name;
      }
      if (this.tablist.city_name) {
        data.city_name = this.tablist.city_name;
      }
      if (this.tablist.district_name) {
        data.district_name = this.tablist.district_name;
      }
      if (this.tablist.company_size) {
        data.company_size = this.tablist.company_size;
      }
      if (this.tablist.iic) {
        data.iic = this.tablist.iic;
      }
      if (this.tablist.iic2) {
        data.iic2 = this.tablist.iic2;
      }
      if (this.tablist.iic3) {
        data.iic3 = this.tablist.iic3;
      }
      if (this.tablist.iic4) {
        data.iic4 = this.tablist.iic4;
      }
      if (this.tablist.company_space) {
        data.company_space = this.tablist.company_space;
      }
      if (this.tablist.company_web) {
        data.company_web = this.tablist.company_web;
      }
      // if (this.tablist.wechat) {
      //   data.wechat = this.tablist.wechat;
      // }

      // if (this.tablist.track_status) {
      //   data.track_status = this.tablist.track_status;
      // }
      // if (this.tablist.merchant_type) {
      //   data.merchant_type = this.tablist.merchant_type;
      // }
      // if (this.tablist.merchant_level) {
      //   data.merchant_level = this.tablist.merchant_level;
      // }
      if (this.tablist.label_ids) {
        data.label_ids = this.tablist.label_ids.split(",");
      }
      if (this.tablist.assist_ids) {
        data.assist_ids = this.tablist.assist_ids.split(",");
      }
  
      if (this.tablist.ext_fields) {
        data.ext_fields = this.tablist.ext_fields;
      }

      if (this.checkboxGroup1.length>0) {
        const arr=[]
        
            this.checkboxGroup1.forEach((el) => {
              arr.push(el.id)
      });
         
        data.main_products_ids=arr
      }
      if (this.tablist.merchant_cate) {
        data.merchant_cate = this.tablist.merchant_cate;
      }
      if (this.tablist.value) {
      let arr=  this.arr
        data.province_name = arr[0]
        data.city_name = arr[1]
        data.district_name = arr[2]
      }
      

      await this.$axios
        .post(this.webAPI + "/CrmPoolDetail/edit", data)
        .then((res) => {
          if (res.msg == "操作成功") {
            this.success("操作成功");
            this.CrmPoolDetail();
          } else {
            this.info(res.msg)
            // this.info(res.msg);
          }
        });
    },
    async followUp() {


      await this.$axios
        .get(this.webAPI + "/CrmBusinessOpportunitiesList/followUp")
        .then((res) => {
          if (res.msg == "操作成功") {

            this.followUpList = res.data;

          } else {
            this.info(res.msg)
          }
        });
    },
    handleClose1(index) {
      this.checkboxGroup1.splice(index, 1)
      // this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    success12() {
      this.checkboxGroup1 = [];
      this.cities.forEach((el) => {
        if (el.isChecked == true) {
          this.checkboxGroup1.push(el);
        }
      });
      this.drawer = false
    },
    handleClose() {
      this.drawer = false
    },
    addExt_fields() {
      if(this.tablist.ext_fields){
        this.tablist.ext_fields.push({ custom_key: '', custom_val: '' })
      }else{
        this.tablist.ext_fields=[{ custom_key: '', custom_val: '' }]
      }
    
   
    },
    delCustom(e) {
      this.tablist.ext_fields.splice(e, 1)
    },
    async zhuyingchanping() {
      let data = {
        code: 'MAIN_PRODUCTS_LABEL',
        status: 1,
      };
      await this.$axios
        .get(this.webAPI + "/CrmOptionsList/index", { params: data })
        .then((res) => {
          if (res.msg == "操作成功") {

            res.data.list.forEach(i => {
              i.isChecked = false
            })
            this.filteredItems = res.data.list;
            this.cities = res.data.list;
          } else {
          }
        });
    },
    async zidingyi() {
        let data = {
          code: "CUSTOM_EXT_FIELDS",
          status: -1,
        };
        await this.$axios
          .get(this.webAPI + "/CrmOptionsList/index", { params: data })
          .then((res) => {
            if (res.msg == "操作成功") {
              this.zidingyiList = res.data.list;
      
              // this.genjinzhuangList = res.data.list;
            } else {
            }
          });
      },
  },
  computed: {
    filteredItems: {
      //   console.log(this.SCinput)

      //    return this.cities.filter(item => item.data.includes(this.SCinput));
      get() {
        return this.cities.filter(item => item.data.includes(this.SCinput)); // 计算属性的 getter
      },
      set(newValue) {
        // 设置逻辑，比如更新 filterValue
        // this.SCinput = newValue; // 您可以根据需要调整逻辑
      }
    },

  },
};
</script>
<style scoped lang="less">
.w700 {
  width: 900px;
  height: 650px;
}

.mr70 {
  margin-right: 70px;
}

.borBom1 {
  border-bottom: 1px solid #d4d4d4;
}

//按钮tab样式
.bo3 {
  border-bottom: 2px solid #4095e5;
}

.mt15 {
  margin-top: 15px;
}

.w270 {
  width: 270px;
}

.w150 {
  width: 135px;
}

.w600 {
  width: 720px;
}

.el-tag+.el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.ml40 {
  margin-left: 40px;
}

.w160 {
  width: 160px;
}

.jubude {

  ::v-deep .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 125px;
  }
}

.w120 {
  width: 160px;
  margin-left: 17px;
}

.wtrp {
  right: -16px;
  top: -16px;
}

.zindex {
  z-index: 3333;
}

.borbb {
  padding-bottom: 20px;
  border-bottom: 2px solid #bdbdbd;
}

.bghhh {
  background: #e4e4e4;
  height: 200px;
  border-radius: 8px;
}

.w100s {
  width: 270px;
}
.h60s1 {
  min-width: 150px;
  height: 30px;
  padding: 0px 10px;
  text-align: center;
}
.bvvo1:hover {
  border: 1px solid #f13a44;
}

.bvvo1 {
  border: 1px solid #c0c4cc;
}

.bgred {
  background: #f13a44;
}
.wtrp1{
    position: absolute;
    // top: 100px;
     right: -20px;
}
.wtrp2{
    position: fixed;
}
.pb10{
  padding-bottom: 10px;
}
</style>
