import Vue from 'vue'

import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import './plugins/element.js'
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/reset.css'
import '@/assets/css/iconfont.css'
import '@/assets/css/common.css'
import JsonExcel from 'vue-json-excel'
import api from './api'
import AIp from './components/AIp.vue';
import pop from './components/pop.vue';
import Pbar from './components/Progressbar.vue';
import records from './components/records.vue';
import EasySearch from './components/EasySearch.vue';
import selectTreeMulti from './components/selectTreeMulti.vue';
import OpenSeaDataPopup from './components/OpenSeaDataPopup.vue';
import MemberListPop from './components/MemberListPop.vue';
import TransferDepartment from './components/TransferDepartment.vue';
import SetType from './components/SetType.vue';
import SetLabel from './components/SetLabel.vue';
import Pwdpop from './components/pwdpop.vue';
import MultipleC from './components/MultipleC.vue';
import Examine from './components/Examine.vue';
import addOPen from './components/addOPen.vue';

import SocialSharing from 'vue-social-sharing'
import instance from "@/utils/request.js";
import directive from './directive' // directive
import uuid from 'uuid';
import App from './App.vue'
if(process.env.NODE_ENV == 'development'){
  Vue.prototype.imgUrl='https://oss.halapala.com'
  Vue.prototype.webAPI = 'https://adminapi.halapala.com'
  // Vue.prototype.webAPI1 = 'http://enapi.halapala.com'

}

if(process.env.NODE_ENV == 'staging'){
  Vue.prototype.imgUrl='https://oss.halapala.com'
  Vue.prototype.webAPI = 'https://adminapi.halapala.com'
// Vue.prototype.imgUrl='https://oss-sz.yoyodeal.cn'
// Vue.prototype.webAPI = 'https://adminapi.yoyodeal.cn'
}

if (process.env.NODE_ENV == 'production') {
  Vue.prototype.imgUrl = 'https://oss-sz.yoyodeal.cn'
  Vue.prototype.webAPI = 'https://adminapi.yoyodeal.cn'
}


Vue.prototype.webAPI1 = 'https://enapi.halapala.com'
Vue.prototype.$axios = instance
Vue.prototype.$api = api
Vue.config.productionTip = false
Vue.use(SocialSharing)
Vue.component('Pbar', Pbar);
Vue.component('records', records);
Vue.component('AIp', AIp);
Vue.component('pop', pop);
Vue.component('EasySearch', EasySearch);
Vue.component('downloadExcel', JsonExcel)
Vue.component('selectTreeMulti', selectTreeMulti)
Vue.component('Examine', Examine)
Vue.component('addOPen', addOPen)


Vue.component('OpenSeaDataPopup', OpenSeaDataPopup)
Vue.component('MemberListPop', MemberListPop)
Vue.component('TransferDepartment', TransferDepartment)
Vue.component('SetType', SetType)
Vue.component('SetLabel', SetLabel)
Vue.component('Pwdpop', Pwdpop)
Vue.component('MultipleC', MultipleC)
Vue.use(ElementUI);
Vue.use(directive)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
