import instance from "@/utils/request.js";
let webAPI = ''
let btns = []
if (process.env.NODE_ENV == 'development') {
  webAPI = 'https://adminapi.halapala.com'
}

if (process.env.NODE_ENV == 'staging') {
  webAPI = 'https://adminapi.halapala.com'
}

if (process.env.NODE_ENV == 'production') {
  webAPI = 'https://adminapi.yoyodeal.cn'
}
const user = {
  state: {
    //   token: getToken(),
    //   id: '',
    //   name: '',
    //   avatar: '',
    permissions: [],
    allList: [],
    menuList: [],
  },

  mutations: {
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_ALL_LIST: (state, allList) => {
      state.allList = allList
    },
    SET_MENU_LIST: (state, menuList) => {
      state.menuList = menuList
    }
  },

  actions: {
    // 获取用户左侧菜单与按钮权限
    GetMenuList({ dispatch, commit }) {
      btns=[]
      return new Promise((resolve, reject) => {
        instance.get(webAPI + "/AdminMenu/profile?ver=1").then(res => {
          if (res.data.list && res.data.list.length) { // 验证返回的roles是否是一个非空数组
            const btn = findAllBtn(res.data.list)
            // console.log('btnfffffffff',btn);
            commit('SET_PERMISSIONS', btn)
            commit('SET_MENU_LIST', res.data.list)
            dispatch('GetInfo')
          } 
          resolve(res)
        }).catch(error => {
          reject(error)
        })

      });
    },
    // 获取用户菜单列表
    GetInfo({ commit, state }) {
      let form = {
        role_id: 0
      }
      return new Promise((resolve, reject) => {
        instance.get(webAPI + "/AdminMenu/list", { params: form }).then(res => {
          if (res.data.list && res.data.list) { // 验证返回的roles是否是一个非空数组
            commit('SET_ALL_LIST', res.data.list)
          } 
          resolve(res)
        }).catch(error => {
          reject(error)
        })

      });
    },
    // 退出系统
    LogOut({ commit }, view) {
      commit('SET_MENU_LIST', [])
      commit('SET_PERMISSIONS', [])
    },
  }
}
// 递归获取按钮list

function findAllBtn(list) {
  list.forEach(val => {
    //type是1表菜单，2为按钮
    if (val.code) {
      btns.push(val.code)
      if (val.children && val.children.length > 0) {
        findAllBtn(val.children)
      }
    } else {
      if (val.children && val.children.length > 0) {
        findAllBtn(val.children)
      }
    }
  })
  return btns
}
export default user