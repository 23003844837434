<template>
  <div>
    <el-select
      :title="multiple? optionData.name : ''"
      ref="select"
      :value="value"
      placeholder="请选择"
      size="mini"
      :clearable="clearable"
      :disabled="disabled"
      :filterable="filterable"
      :filter-method="filterMethod"
      style="width: 100%;"
      @clear="clear"
      @visible-change="visibleChange"
    >
      <el-option
        ref="option"
        class="tree-select__option"
        :value="optionData.id"
        :label="optionData.name"
      >
        <el-tree
          ref="tree"
          class="tree-select__tree"
          :class="`tree-select__tree--${multiple ? 'checked' : 'radio'}`"
          :node-key="nodeKey"
          :data="data"
          :props="props"
          :default-expanded-keys="[value]"
          :show-checkbox="multiple"
          :highlight-current="!multiple"
          :expand-on-click-node="multiple"
          :filter-node-method="filterNode"
          @node-click="handleNodeClick"
          @check-change="handleCheckChange"
        ></el-tree>
      </el-option>
    </el-select>
  </div>
  </template>
  
  <script>
  export default {
    name: 'TreeSelect',
    props: {
      // v-model绑定
      value: {
        type: [String, Number],
        default: ''
      },
      multiple: {
        type: Boolean,
        default: false
      },
      clearable: {
        type: Boolean,
        default: false
      },
      // 树形的数据
      data: {
        type: Array,
        default: function () {
          return []
        }
      },
      // 每个树节点用来作为唯一标识的属性
      nodeKey: {
        type: [String, Number],
        default: 'id'
      },
      // 是否可搜索
      filterable: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      // tree的props配置
      props: {
        type: Object,
        default: function () {
          return {
            label: 'name',
            children: 'children'
          }
        }
      }
    },
    data() {
      return {
        optionData: {
          id: '',
          name: ''
        },
        filterFlag: false
      }
    },
    watch: {
      value: {
        handler(val) {
          // console.log(111);
          if (!this.isEmpty(this.data)&&val!=0) {
            // console.log('123456',val);
            this.init(val)
          }
        },
        immediate: true
      },
      data: function (val) {
        // console.log(222);
        if (!this.isEmpty(val)) {
          this.init(this.value)
        }
      }
    },
    created() {},
    methods: {
      // 是否为空
      isEmpty(val) {
        for (let key in val) {
          return false
        }
        return true
      },
      handleNodeClick(data) {
        if (this.multiple) {
          return
        }
        // console.log('data[this.nodeKey]',data[this.nodeKey]);
        this.$emit('input', data[this.nodeKey])
        this.$emit('changeDepartment')
        this.$refs.select.visible = false
      },
      handleCheckChange() {
        const nodes = this.$refs.tree.getCheckedNodes()
        const value = nodes.map((item) => item[this.nodeKey]).join(',')
        // console.log(value,'8888888888');
        this.$emit('input', value)
      },
      init(val) {
        // console.log('77777777777');
        // 多选
        if (this.multiple) {
          const arr = val.toString().split(',')
          this.$nextTick(() => {
            this.$refs.tree.setCheckedKeys(arr)
            const nodes = this.$refs.tree.getCheckedNodes()
            this.optionData.id = val
            this.optionData.name = nodes
              .map((item) => item[this.props.label])
              .join(',')
          }) 
        }
        // 单选
        else {
          val = val === '' ? null : val
          // console.log(666);
            this.$nextTick(() => {
              // console.log(val);
              this.$refs.tree.setCurrentKey(val)
              if (val === null) {
                this.optionData.id = ''
                this.optionData.name = ''
                return
              }
              const node = this.$refs.tree.getNode(val)
              // console.log(node,val);
              // console.log(this.props.label);
              this.optionData.id = val
              this.optionData.name = node.data[this.props.label]
            })
        }
      },
      visibleChange(e) {
        if (e) {
          const tree = this.$refs.tree
          this.filterFlag && tree.filter('')
          this.filterFlag = false
          let selectDom = null
          if(this.multiple) {
            selectDom = tree.$el.querySelector('.el-tree-node.is-checked')
          } else {
            selectDom = tree.$el.querySelector('.is-current')
          }
          setTimeout(() => {
            this.$refs.select.scrollToOption({ $el: selectDom })
          }, 0)
        }
      },
      clear() {
        this.$emit('input', '')
      },
      filterMethod(val) {
        this.filterFlag = true
        this.$refs.tree.filter(val)
      },
      filterNode(value, data) {
        if (!value) return true
        const label = this.props.label || 'name'
        return data[label].indexOf(value) !== -1
      }
    }
  }
  </script>
  
  <style scoped lang="less">
  ::v-deep .el-input__inner{
    height: 32px;
    line-height: 32px;
  }
  .tree-select__option {
    &.el-select-dropdown__item {
      height: auto;
      line-height: 1;
      padding: 0 0 20px;
      background-color: #fff;
    }
  }
  
  .tree-select__tree {
    padding: 4px 20px;
    font-weight: 400;
    &.tree-select__tree--radio {
      .el-tree-node.is-current > .el-tree-node__content {
        // color: $mainColor;
        font-weight: 700;
      }
    }
  }
  </style>