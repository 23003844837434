<template>
    <div class="over" >
     <div class="modal">
       <slot></slot>
     </div>
   </div>
 </template>
 
 <script>
 export default {
   data () {
     return {
       
     }
   },
   components: {}
 }
 </script>
 <style scoped lang="less">
 .over {
 position: fixed;
 top: 10%;
 left: 16%;
//  width: 100%;
//  height: 100%;
//  background-color: rgba(0, 0, 0, 0.5);
 display: flex;
 justify-content: center;
 align-items: center;
 z-index: 100;

}

.modal {
 background-color: #fff;
 padding: 20px;
 border-radius: 5px;
}

.close-btn {
 margin-top: 10px;
}
 </style>