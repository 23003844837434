<template>
     <div class="overlay" >
      <div class="modal pr ovs2">
        <slot></slot>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data () {
      return {
        
      }
    },
    components: {}
  }
  </script>
  <style scoped lang="less">
  .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  
}

.modal {
  background-color: #fff;
  padding: 25px;
  border-radius: 5px;
  max-height: 700px;

  z-index: 3002;
}

.close-btn {
  margin-top: 10px;
}
  </style>