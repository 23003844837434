<template>
  <div class="home bgh pdl20">
    <div class="number jcl aic gap24">
      <div class="column br6">
        <div class="co1">用户数量 <i class="co3">单位/人</i></div>
        <div class="co2">{{ index.user_num }}</div>
      </div>
      <div class="column br6">
        <div class="co1">认证数量 <i class="co3">单位/个</i></div>
        <div class="co2">{{ index.auth_num }}</div>
      </div>
      <div class="column br6">
        <div class="co1">产品数量 <i class="co3">单位/件</i></div>
        <div class="co2">{{ index.product_num }}</div>
      </div>
      <div class="column br6">
        <div class="co1">订单数量 <i class="co3">单位/个</i></div>
        <div class="co2">{{ index.order_num }}</div>
      </div>
    </div>
    <div class="flex jcl aic gap24 pt20">
      <div class="user1 br16 bgb">
        <div class="userName">认证用户占比</div>
        <div id="user"></div>
      </div>
      <div class="firms1 br16 bgb">
        <div class="userName">认证数量-月份柱状图</div>
        <div id="firms"></div>
      </div>
    </div>
    <div class="flex jcl aic gap24 pt20">
      <div class="w48 br16 bgb">
        <div class="userName">用户数量</div>
        <div id="userS"></div>
      </div>
      <div class="w48 br16 bgb">
        <div class="userName">认证数量</div>
        <div id="authentication"></div>
      </div>
    </div>
    <div class="flex jcl aic gap24 pt20">
      <div class="w48 br16 bgb">
        <div class="userName">产品数量</div>
        <div id="production"></div>
      </div>
      <div class="w48 br16 bgb">
        <div class="userName">订单数量</div>
        <div id="Order"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      total: [],
      token: localStorage.token,
      index:{},
      authComplexByMonth:{},

    };
  },
  created() {
    //汇总统计
    this.demoApi();
    
  },
  mounted() {
    //必须在页面渲染后请求
    //认证数量
    this.demoApi1()
    //企业/个人/未认证/比例
    this.demoApi2()
    //按月汇总注册人数
    this.demoApi3()
    //按月汇总认证信息
    this.demoApi4()
    //按月汇总产品
    this.demoApi5()
     //按月汇总订单
    this.demoApi6()


    
  },
  methods: {
    async demoApi() {
      const form = new FormData();
      await this.$axios
        .get(this.webAPI + "/Stat/index", form)

        .then((res) => {
          // 请求成功，处理响应数据
          
          this.index=res.data
        })
        .catch((error) => {
          // 请求失败，处理错误
          console.error(error);
        });
    },
    async demoApi1() {
      const form = new FormData();
      form.append("num", "12");
      await this.$axios
        .get(this.webAPI + "/Stat/authComplexByMonth", form)
        .then((res) => {
          // 请求成功，处理响应数据
          // console.log(res.data);
          let arr=[]
          arr.push(["product", "企业认证", "个人认证"])
          this.authComplexByMonth=res.data
          this.authComplexByMonth.auth_type_1.forEach(item => {
              this.authComplexByMonth.auth_type_2.forEach(item1 => {
                if(item.cycle==item1.cycle){
                  arr.push([item.cycle,item.total,item.total])
                }
             
          });
          });
        this.firmsLine(arr);
        })
        
    },
    async demoApi2() {
      const form = new FormData();

      await this.$axios
        .get(this.webAPI + "/Stat/authNumberPersonnel", form)
        .then((res) => {
          // 请求成功，处理响应数据
          //  console.log(res.data);
           this.line(res.data)
        })
    },
    async demoApi3() {
      let arr1=[]
      let arr2=[]
      const form = new FormData();
      form.append("num", "12");
      await this.$axios
        .get(this.webAPI + "/Stat/userByMonth", form)
        .then((res) => {
          // 请求成功，处理响应数据
          res.data.list.forEach(item=>{
             if(item.cycle){
              arr1.push(item.cycle)
             }
             if(item.total>-1){
              arr2.push(item.total)
             }
          })
         
           this.userS(arr1,arr2)
        })
    },
    async demoApi4() {
      let arr1=[]
      let arr2=[]
      const form = new FormData();
      form.append("num", "12");
      await this.$axios
        .get(this.webAPI + "/Stat/authByMonth", form)
        .then((res) => {
          // 请求成功，处理响应数据
          res.data.list.forEach(item=>{
             if(item.cycle){
              arr1.push(item.cycle)
             }
             if(item.total>-1){
              arr2.push(item.total)
             }
          })
          this.authentication(arr1,arr2)
          
        })
    },
    async demoApi5() {
      let arr1=[]
      let arr2=[]
      const form = new FormData();
      form.append("num", "2");
      await this.$axios
        .get(this.webAPI + "/Stat/productByMonth", form)
        .then((res) => {
          // 请求成功，处理响应数据
          res.data.list.forEach(item=>{
             if(item.cycle){
              arr1.push(item.cycle)
             }
             if(item.total>-1){
              arr2.push(item.total)
             }
          })
          this.production(arr1,arr2)
          
        })
    },
    async demoApi6() {
      let arr1=[]
      let arr2=[]
      const form = new FormData();
      form.append("num", "2");
      await this.$axios
        .get(this.webAPI + "/Stat/productByMonth", form)
        .then((res) => {
          // 请求成功，处理响应数据
          res.data.list.forEach(item=>{
             if(item.cycle){
              arr1.push(item.cycle)
             }
             if(item.total>-1){
              arr2.push(item.total)
             }
          })
          this.Order(arr1,arr2);
          
        })
    },
    line(arrx, echartsMoney, echartsSaleAmount) {
      //用户数量折线图
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("user"));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "5%",
          left: "center",
        },
        series: [
          {
            name: "认证用户占比",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "center",
            },

            labelLine: {
              show: false,
            },
            data: [
              { value: arrx.auth_type_0, name: "未认证", itemStyle: { color: "#5C7BD9" } },
              { value: arrx.auth_type_1, name: "个人认证", itemStyle: { color: "#91CC75" } },
              { value: arrx.auth_type_2, name: "企业认证", itemStyle: { color: "#73C0DE" } },
            ],
          },
        ],
      });
    },
    firmsLine(arrx, echartsMoney, echartsSaleAmount) {
      //企业数量折线图
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("firms"));
      // 绘制图表
      myChart.setOption({
        legend: {
          left: "center",
        },
        tooltip: {},
        dataset: {
          source: arrx,
        },
        xAxis: { type: "category" },
        yAxis: {},
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
          { type: "bar", itemStyle: { color: "#73C0DE" } },
          { type: "bar", itemStyle: { color: "#91CC75" } },
        ],
      });
    },
    userS(arrx, echartsMoney, echartsSaleAmount) {
      //产品数量数量折线图
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("userS"));
      // 绘制图表
      myChart.setOption({
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: arrx,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: echartsMoney,
            type: "line",
            areaStyle: {},

            smooth: true,
            emphasis: {
              label: {
                show: true,
                fontSize: 20,
              },
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  // 渐变颜色
                  {
                    offset: 0,
                    color: "#8088E1",
                  },
                  {
                    offset: 1,
                    color: "#FFFFFF",
                  },
                ],
                global: false,
              },
            },
          },
        ],
      });
    },
    production(Data,Data1) {
      //产品数量
      var chartDom = document.getElementById("production");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: Data,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: Data1,
            type: "line",
            areaStyle: {},
            smooth: true,
            emphasis: {
              label: {
                show: true,
                fontSize: 20,
              },
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  // 渐变颜色
                  {
                    offset: 0,
                    color: "#85BC6B",
                  },
                  {
                    offset: 1,
                    color: "#FFFFFF",
                  },
                ],
                global: false,
              },
            },
          },
        ],
      };
      option && myChart.setOption(option);
    },
    Order(Data,Data1) {
      //订单数量
      var chartDom = document.getElementById("Order");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: Data,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: Data1,
            type: "line",
            areaStyle: {},
            smooth: true,
            emphasis: {
              label: {
                show: true,
                fontSize: 20,
              },
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  // 渐变颜色
                  {
                    offset: 0,
                    color: "#D9E6FD",
                  },
                  {
                    offset: 1,
                    color: "#FFFFFF",
                  },
                ],
                global: false,
              },
            },
          },
        ],
      };
      option && myChart.setOption(option);
    },

    //认证数量
    authentication(Data,Data1) {
      var chartDom = document.getElementById("authentication");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        xAxis: {
          type: "category",
          boundaryGap: false,
          data:Data,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: Data1,
            type: "line",
            areaStyle: {},
            smooth: true,
            emphasis: {
              label: {
                show: true,
                fontSize: 20,
              },
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  // 渐变颜色
                  {
                    offset: 0,
                    color: "#FDE8D4",
                  },
                  {
                    offset: 1,
                    color: "#FFFFFF",
                  },
                ],
                global: false,
              },
            },
          },
        ],
      };
      option && myChart.setOption(option);
    },
  },

  // 数据过滤
  filters: {
    num(v) {
      if (!v) return;
      return v.toFixed(2);
    },
  },
  components: {},
};
</script>
<style scoped lang="less">
.home {
  padding-top: 24px;
  padding-bottom: 24px;
  .number {
    display: flex;
    margin: 0 auto;

    .column {
      width: 23%;
      height: 108px;
    }
    .column:nth-child(1) {
      background: url("@/assets/img/i1.png") no-repeat center;
      background-size: 100%;
    }
    .column:nth-child(2) {
      background: url("@/assets/img/i2.png") no-repeat center;
      background-size: 100%;
    }
    .column:nth-child(3) {
      background: url("@/assets/img/i3.png") no-repeat center;
      background-size: 100%;
    }
    .column:nth-child(4) {
      background: url("@/assets/img/i4.png") no-repeat center;
      background-size: 100%;
    }
  }
  .userName {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
    color: rgba(36, 4, 101, 1);
    padding-left: 10px;
    border-left: 2px solid rgba(128, 136, 225, 1);
  }
  .user1 {
    width: 23%;
    height: 378px;
    padding-top: 24px;

    #user {
      width: 100%;
      height: 100%;
    }
  }
  .firms1 {
    width: 73%;
    height: 378px;
    padding-top: 24px;
    #firms {
      width: 100%;
      height: 100%;
    }
  }

  .w48 {
    width: 48%;
    height: 378px;
    padding-top: 24px;
    #userS {
      width: 100%;
      height: 100%;
    }
    #Products {
      width: 100%;
      height: 100%;
    }
    #authentication {
      width: 100%;
      height: 100%;
    }
    #production {
      width: 100%;
      height: 100%;
    }
    #Order {
      width: 100%;
      height: 100%;
    }
  }

  .gap24 {
    gap: 24px;
  }
  .co1 {
    color: #240465;
    padding-top: 18px;
    padding-left: 20px;
    padding-bottom: 26px;
  }
  .co2 {
    color: #5563ff;
    padding-left: 20px;
    font-weight: 700;
    font-size: 20px;
  }
  .co3 {
    font-size: 10px;
    padding-left: 8px;
    color: rgba(36, 4, 101, 0.4);
  }
  .br16 {
    border-radius: 16px;
  }
}
.pdl20 {
  padding-left: 24px;
}
</style>
