<template>
  <pop v-show="isMemShow">
    <div class="w700 pr">
      <div class="flex jcr aic pa wtrp">
        <i class="el-icon-close f18 cp" @click="CloseShow"></i>
      </div>
      <div class="borbb ba80 f18">成员列表</div>
      <div class="bgbbb">
        <div class="biankk mt10">
          <div class="borbbhh">
            <div class="zuzhi">组织架构</div>
          </div>
          <div class="flex jcsb ovs2">
            <div class="mr1bb flex30 pd10">
              <el-tree
                :data="data"
                show-checkbox
                node-key="id"
                :props="defaultProps"
                @check-change="checkNode"
              ></el-tree>
            </div>
            <div class="flex70" v-if="!phantom">
              <div class="">
                <!-- <el-radio-group v-model="radio">
                <el-radio class="lh30" :label="item.id" v-for="(item,index) in pensonList" :key="index">{{item.username}}</el-radio>
              </el-radio-group> -->
                <el-cascader-panel
                v-model="radio"
                :options="pensonList"
                :props="props"
                @change="scchange"
                ref="cascader"
              ></el-cascader-panel>
    
              </div>
            </div>
            <div class="flex70" v-if="phantom">
              <div class="lh30">
                <!-- <el-checkbox-group v-model="checkList">
                  <el-checkbox :label="item.id" v-for="(item,index) in pensonList" :key="index">{{item.username}}</el-checkbox>

                </el-checkbox-group> -->
                <el-cascader-panel
                v-model="checkList"
                :options="pensonList"
                :props="props1"
                @change="scchange"
                ref="cascader"
              ></el-cascader-panel>
              
              </div>
            </div>
          </div>
        </div>
        <div class="flex jcr mt10">
          <el-button plain size="mini" @click="CloseShow" >取消</el-button>
          <el-button type="primary" plain size="mini" @click="queding" >确定</el-button>
        </div>
      </div>
    </div>
  </pop>
</template>
  
  <script>
export default {
  data() {
    return {
      phantom: true,
      radio: [],
      isMemShow: false,
      tableData: [], //列表数据
      textarea: "",
      status: "", //
      dynamicTags: ["标签一"],
      inputVisible: false,
      inputValue: "",
      options: [
 
      ],
      value: "",
      isTrue: false,
      page: 1,
      size: "",
      form: {}, //搜索
      followData: [], //选中
      checked: false, //全选
      input: "",
      data: [
    
      ],
      defaultProps: {
        children: "children",
        label: "name"
      },
      department_id:'',
      checkList:[],
      pensonList:[],
      props: {
        checkStrictly:true,
        children: "children",
        label: "nick",
        value: "id",
      },
      props1: {
        checkStrictly:true,
        children: "children",
        label: "nick",
        value: "id",
        multiple:true,
      },
    };
  },
  mounted() {
    this.isTrue = true;
    this.Department();
  },

  methods: {
 
    // 关闭弹窗
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    checkNode(e,i){

      if(i){
          this.department_id=e.id
          this.ShopAuth()
      }
    },
    //获取部门
    async Department() {
      let data = {
        power: 1, //角色：-1=全部 0无 1crm
        name: "" //名称
      };
      await this.$axios
        .get(this.webAPI + "/AdminDepartment/index", {
          params: data
        })
        .then(res => {
          if (res.code == 200) {
            // 请求成功，处理响应数据
            this.data = res.data.list;
            
          }
        });
    },

    //列表数据初始化
    async ShopAuth() {

      let data={
        pid:-1,
        department_id:this.department_id,
        position_id:0
      }


      await this.$axios
        .get(this.webAPI + "/AdminUser/index", {
          params: data
        })
        .then(res => {
          // 请求成功，处理响应数据
          console.log(res.data, "列表数据");
          this.pensonList=res.data.list
        });
    },
 
    CloseShow() {
      this.isMemShow = false;
      this.checkList=[]
      this.radio=[]
    },
    OPenShow() {
      this.checkList=[]
      this.radio=[]
      this.isMemShow = true;
    },
    chosephantomF(){
      this.Department()
      this.phantom=false
    },
    chosephantomT(){
      this.Department()
      this.phantom=true
    },
    queding(){
      if(this.phantom){
      
 
const flattenedArray = this.checkList.flat(Infinity);

        this.$root.$emit('fenpeis',flattenedArray)
      }else{
        this.$root.$emit('fenpeis',this.radio[this.radio.length-1])
      }
      
      this.CloseShow()
    },
    scchange(e){
     

    },

  },
  components: {}
};
</script>
  <style scoped lang="less">
.w700 {
  width: 900px;
  min-height: 750px;
}
.mr70 {
  margin-right: 70px;
}
.borBom1 {
  border-bottom: 1px solid #d4d4d4;
}
//按钮tab样式
.bo3 {
  border-bottom: 2px solid #4095e5;
}
.mt15 {
  margin-top: 15px;
}
.w270 {
  width: 270px;
}
.w150 {
  width: 135px;
}
.w600 {
  width: 720px;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.ml40 {
  margin-left: 40px;
}
.w160 {
  width: 160px;
}
.jubude {
  ::v-deep .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 125px;
  }
}
.w120 {
  width: 160px;
  margin-left: 17px;
}
.wtrp {
  right: -16px;
  top: -16px;
}
.zindex {
  z-index: 3333;
}
.borbb {
  padding-bottom: 20px;
  border-bottom: 2px solid #bdbdbd;
}
.bghhh {
  background: #e4e4e4;
  height: 200px;
  border-radius: 8px;
}
.bgbbb {
  padding: 10px 20px;
  height: 600px;
  // background: #c6f8bc;
}
.biankk {
  border: 1px #ccc solid;
}
.zuzhi {
  margin-left: 10px;
  width: 64px;
  line-height: 30px;
  color: #11ba9e;
  // padding-bottom: 10px;
  border-bottom: 2px solid #11ba9e;
}
.mr1bb {
  border-right: 1px solid #ccc;
}
.borbbhh {
  border-bottom: 1px solid #f0f0f2;
}
.lh30 {
  line-height: 30px;
  padding-left: 10px;
}
/deep/ .el-scrollbar__wrap{
  margin-bottom: 0 !important;
  margin-right:0 !important;
}
</style>