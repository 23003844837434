<template>
  <pop>
    <div class="w900 pr ova">
      <div class="f18 textac">记录</div>
      <div class="flex jcl gap8 pt20">
        <div class="w90 textar f14 pt10">用户跟进记录:</div>
        <div class="flex1">
          <el-input
            type="textarea"
            :rows="6"
            placeholder="请输入内容"
            v-model="textarea"
          >
          </el-input>
          <div class="flex jcsb aic">
            <div class="flex jcl aic gap8 pt10">
              <el-button @click="pusht('供销关系:')">供销关系</el-button>
              <el-button @click="pusht('供应关系:')">供应关系</el-button>
              <el-button @click="pusht('人物画像:')">人物画像</el-button>
            </div>
            <div>
              <el-button type="primary" @click="adds"> 保 存 </el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="pt20">
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          :header-cell-style="{
            'text-align': 'center',
            background: '#E8E8E8',
            color: '#000000',
          }"
          :cell-style="{ 'text-align': 'center', padding: '4px 0px' }"
        >
          <el-table-column fixed prop="id" label="ID"> </el-table-column>
          <el-table-column fixed prop="user_id" label="用户ID"> </el-table-column>
		   <el-table-column fixed prop="user_nick" label="用户昵称"> </el-table-column>
          
          <el-table-column prop="admin_nick" label="跟进人"> </el-table-column>
          <el-table-column prop="message" label="记录内容"> </el-table-column>
          <el-table-column prop="create_time" label="创建时间">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="240">
            <template slot-scope="scope">
              <el-button type="danger" @click="open(scope.row)" string="mini"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="pt10 jcc flex">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="allPage"
            :pageSize="5"
            @prev-click="prev"
            @next-click="next"
            @current-change="current"
            :current-page="page"
          >
          </el-pagination>
        </div>
      </div>
      <div class="pa tx0 cp" @click="close"><i class="el-icon-close"></i></div>
    </div>
  </pop>
</template>

<script>
export default {
  props: ["message",'origin_site'],
  watch: {
    message(newVal, oldVal) {
      // console.log(newVal,123); // 获取最新的props值
      this.user_id =  newVal
       this.UserTrackingLog()
    },
  },
  data() {
    return {
      tableData: [],
      textarea: "",
      flag: true,
      form: {},
      page: 1,
      user_id:'',
      allPage:0,
    };
  },
  components: {},
  mounted() {
  this.UserTrackingLog()
    // console.log(this.message,'this.message');
    
  },
  methods: {
    //上一页
    prev(v) {
      this.page=v
      this.UserTrackingLog()
    },
    //下一页
    next(v) {
      this.page=v
      this.UserTrackingLog()
    },
    //选中页
    current(v) {
      this.page=v
      this.UserTrackingLog()
    },
    pusht(v) {
      this.textarea = this.textarea + v;
    },
    close() {
      this.$emit("closed", false);
    },
    //提示删除
    open(item) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.dels(item);
        })
        .catch(() => {});
    },
    success(v) {
      this.$message({
        type: "success",
        message: v,
      });
    },
    error(v) {
      this.$message({
        type: "error",
        message: v,
      });
    },
    //删除
    async dels(v) {
      let data = {
        ids: v.id,
      };
      await this.$axios.post(this.webAPI + "/UserTrackingLog/del", data).then((res) => {
        // 请求成功，处理响应数据
        if (res.msg == "操作成功") {
          this.success("删除成功");
          this.UserTrackingLog()
        } else {
          this.error("删除失败");
        }
      });
    },
    //添加
    async adds(v) {
      let data = {
        user_id:this.user_id,
        message:this.textarea
      };
      await this.$axios.post(this.webAPI + "/UserTrackingLog/add", data).then((res) => {
        // 请求成功，处理响应数据
        if (res.msg == "操作成功") {
          this.success(res.msg);
          this.UserTrackingLog()
        } else {
          this.error(res.msg);
        }
      });
    },
    async UserTrackingLog() {
      // console.log(this.message,'user_id');
      this.form.page = this.page;
      this.form.size = 5;
      this.form.user_id = this.user_id;
      this.form.origin_site = this.origin_site;
      await this.$axios
        .get(this.webAPI + "/UserTrackingLog/index", {
          params: this.form,
        })
        .then((res) => {
          // 请求成功，处理响应数据
          // console.log(res.data)

          this.tableData = res.data.data;
          this.allPage = res.data.total;
        });
    },
  },
};
</script>
<style scoped lang="less">
.w900 {
  width: 900px;
  height: 600px;
}
.tx0 {
  top: 0px;
  right: 0;
  font-size: 20px;
}
</style>
