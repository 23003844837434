<template>
    <div v-show="show"  >
      <pop>
        <div class="br6 pd10 bgb">
        <!-- 审核弹窗 -->
        <div class="w400s f14">
          <div class="flex f18">
            <div class="flex1 textac">审核</div>
            <div>
              <i class="el-icon-close cp" @click="close()"></i>
            </div>
          </div>

          <div class="w100 flex jcl pt20 gap16">
            <div class="w90 textar">审核状态</div>
            <div class="flex jcl flex70">
              <el-radio-group v-model="status">
                <el-radio :label="1">审核通过</el-radio>
                <el-radio :label="0">未通过</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div v-if="status == 0">
            <div class="flex jcl aic pt20 gap16">
              <div class="redB w90  textar">审核原因</div>
              <div class="pl10 flex70">
                <el-select v-model="value11" @change="changeOP" placeholder="请选择">
                  <el-option v-for="(item, index) in options3" :key="index" :label="item.content"
                    :value="item.content"></el-option>
                </el-select>
              </div>
            </div>
            <div class="flex jcl aic pt10 gap16">
              <div class="redB w90  textar">内容</div>
              <div class="pl10 flex70">
                <el-input type="textarea" placeholder="请输入内容" v-model="value22" :rows="6" maxlength="100"
                  show-word-limit></el-input>
              </div>
            </div>
          </div>
          <div class="w100 flex jcl pt20 gap16">
            <div class="w90 textar">用户跟进记录</div>
            <div class="flex70">
              <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 10 }" placeholder="请输入内容"
                v-model="tracking_message"></el-input>
            </div>
          </div>
          <div class="h100 flex jcc aic">
            <el-button type="primary" @click="save()">保存</el-button>
          </div>
        </div>
      </div>
    </pop>
    </div>
  </template>
    
    <script type="text/javascript">
  export default {
    props:['type','id'],
    watch:{
      type(newVal, oldVal) {
      console.log(newVal); // 获取最新的props值
      
       this.Content()
    },
    },
    data() {
      return {
        status:0,
        show:false,
        value11:'',
        value22:'',
        options3:[],
        tracking_message:'',
      };
    },
  
    methods: {
        close(){
            this.show=false
        },
            //审核原因列表
       async Content() {
      let data = {
        origin_site: 'cn',
  
      };
      if(this.type==1){ 
        data.tid=6
 
      }
      if(this.type==2){
        data.tid=5
      }
      if(this.type==3){
        data.tid=7
      }
      
      await this.$axios
        .get(this.webAPI + "/ReviewTemplate/simple", {
          params: data
        })
        .then(res => {
          this.options3 = res.data.list;
        });
    },
    changeOP(e) {

      this.value22 = e;
    },
    open(){
      this.show=true
    },
    async save() {
      let data = {
        id: this.id,
        status: this.status,
        remarks: this.value22,
        tracking_message: this.tracking_message
      };
      let url=''
      if(this.type==1){ 
        url = "/UserBuy/review";
 
      }
      if(this.type==2){
             url='/UserAssets/review'
      }
      if(this.type==3){
             url='/JobPositions/audit'
             data.val=this.status
      }
     
      await this.$axios
        .post(this.webAPI + url, data)
        .then(res => {
          // 请求成功，处理响应数据
          // console.log(res.data)
          if (res.msg == "操作成功") {
            this.$root.$emit('updata',1)
            this.success1(res.msg);
            this.close();
          } else {
            this.error(res.msg);
          }
        });
    },
    success1(v) {
      this.$message({
        type: "success",
        message: v
      });
    },
    error(v) {
      this.$message({
        type: "error",
        message: v
      });
    },
   
    },
    created() {
        
    },
    mounted() {
      
        this.Content()
    }
  };
  </script>
<style  lang="less" scoped>
 .w400s {
  width: 460px;

  .w100 {
    width: 100%;
  }

  .flex30 {
    flex-basis: 30%;
  }

  .flex70 {
    flex-basis: 70%;
  }

  .h100 {
    height: 100px;
  }
}
.w90 {
  width: 90px;
}
  </style>
    