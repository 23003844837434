<template>
    <div class="w1000">
        <el-progress  :percentage="lol"></el-progress>
   </div>
 </template>
 
 <script>
 export default {

   data () {
     return {
       lol:1
     }
   },
   components: {},
   mounted(){
    
   },
   methods:{
    daochu(){
      
      this.lol=1
       
          const timer = setInterval(() => {
            this.lol++;
            // 如果达到特定条件，可以停止定时器
            if ( this.lol>= 100) {

              clearInterval(timer);
              this.close()
            }
          },40);
    },
    close() {
      this.$emit('custom-event', false);
    },
   
   },
 }
 </script>
 <style scoped lang="less">
    .w1000{
        width: 50vw;
    }
 </style>