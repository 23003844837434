<template>
  <!-- 筛选条件 -->
  <pop v-show="isSS">
    <div class="pr w600">
        <div class="flex jcsb aic">
            <div class="f16">筛选出符合以下的数据</div>
            <div class="f16 cp"> <i class="el-icon-close f20" @click="closeW"></i></div>
        </div>
     
      <el-dropdown trigger="click" @command="EditOP">
        <span class="el-dropdown-link">
          <div class="f14 pt10 cp">
            <i class="el-icon-plus"></i>
            <i class="blue">添加过滤条件</i>
          </div>
        </span>

        <el-dropdown-menu slot="dropdown">
          <div class="h400 ovs2">
            <el-dropdown-item
              v-for="(item, index) in CrmOptionsList"
              :key="index"
              :command="item"
            >
              <div>{{ item.data }}</div></el-dropdown-item
            >
          </div>
        </el-dropdown-menu>
      </el-dropdown>
      <div class="pt10">
        <div
          class="flex jcl aic gap20 pt10"
          v-for="(item, index) in term"
          :key="index"
        >
          <div class="f14 w100s">{{ item.name }}</div>
          <div class="w300" v-if="item.type == 'text'">
            <el-input v-model="item.value" placeholder="请输入内容"></el-input>
          </div>
          <div class="w300" v-if="item.type == 'select'">
            <el-select v-model="item.value" placeholder="请选择">
              <el-option
                v-for="i in item.data"
                :key="i.key"
                :label="i.value"
                :value="i.key"
              >
              </el-option>
            </el-select>
          </div>
          <div class="w300" v-if="item.type == 'sel'">
            <el-cascader
              :props="props"
              v-model="item.value"
              @change="handleChange4"
              placeholder="请选择"
              style="width: 300px"
            ></el-cascader>
          </div>
          <div>
            <i class="el-icon-delete f18 cp" @click="delTList(index)"></i>
          </div>
        </div>
      </div>
      <div class="pt30">
        <div class="flex jcl aic gap10">
          <div>
            <el-button
              type="success"
              class="f14"
              size="mini"
              @click="quedingduo"
              >确定</el-button
            >
          </div>
          <div class="flex jcl aic cp" @click="clearOpen">
            <i class="f16 el-icon-s-open"></i>
            <i class="f14">清除</i>
          </div>
        </div>
      </div>
    </div>
  </pop>
</template>

<script>
export default {
  props: ["CrmOptionsList"],
  data() {
    let that=this
    return {
      isSS: false,
      options: [],
      department_id: "",
      props: {
        lazy: true,
        checkStrictly: false,
        lazyLoad: (node, resolve) => {
          const { level } = node; // node 节点数据
          const nodes = []; // 动态节点
          let type = level == 0 ? "0" : node.value; // 0 代表第一次请求
          that
            .provinceFn(type, level)
            .then((res) => {
              res.list.map((item) => {
                let area = {
                  value: item,
                  label: item,
                  leaf: node.level >= 1,
                };
                nodes.push(area);
              });
              resolve(nodes); // 回调
            })
            .catch((error) => {
              console.log(error);
            });
        },
      },
      term:[],
    };
  },
  mounted() {

    // this.Department();
  },

  methods: {
    closeW() {
      this.isSS = false;
    },
    open() {
      this.isSS = true;
    },
    // 关闭弹窗
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    // 编辑筛选条件
    async EditOP(e) {
      let op3 = JSON.parse(e.ext);
      if (this.term.length > 0) {
        if (this.term.some((i) => i.name === e.data)) {
          this.info("请勿重复添加");
          return;
        }
      }
      if (op3.source == 0 && op3.type == "text") {
        this.term.push({
          name: e.data,
          key: e.custom_key,
          value: "",
          type: op3.type,
        });
      }
      if (op3.source == 1 && op3.type == "select") {
        let data = await this.corresponding(op3);
        this.term.push({
          name: e.data,
          key: e.custom_key,
          value: "",
          type: op3.type,
          data: data,
        });
      }
      if (op3.source == 2 && op3.type == "select") {
        if (e.data == "所属市") {
          this.term.push({
            name: e.data,
            key: e.custom_key,
            value: "",
            type: "sel",
          });
        } else {
          this.term.push({
            name: e.data,
            key: e.id,
            value: "",
            type: op3.type,
          });
        }
      }
      if (op3.source == 0 && op3.type == "select") {
        this.term.push({
          name: e.data,
          key: e.custom_key,
          value: "",
          type: op3.type,
          data: op3.data,
        });
      }

      // let data = formData;
    },
    async corresponding(e) {
      let data = {
        code: e.data.code,
      };
      try {
        let response = await this.$axios.get(this.webAPI + "/CrmComplex/read", {
          params: data,
        });
        if (response.code == "200") {
          return response.data.list;
        } else {
          // 处理错误情况

          return null; // 或者抛出异常
        }
      } catch (error) {
        // 捕获并处理异常
        // this.info("发生错误：" + error.message);
        console.error("发生错误：" + error.message);
        return null; // 或者抛出异常
      }
    },
     //省市区都是这一个
     async provinceFn(v, i) {
      // this.date.pid = v;

      let data = {};
      if (i == 0) {
        data.region_field = "province_name";
      } else if (i == 1) {
        this.province_name = "v";
        data.region_field = "province_name";
        data.region_val = v;
      } else {
        data.region_field = "city_name";
        data.region_val = v;
      }
      const res = await this.$axios.get(
        this.webAPI + "/CrmImportPoolEnum/region",
        {
          params: data,
        }
      );
      return res.data;
    },
    quedingduo(){
      this.isSS = false;
      this.$root.$emit("duotiaojian", this.term);
      this.closeW();

    //   console.log(this.term)
      
    },
    clearOpen() {
      this.isSS = false;
      this.term = [];
    },
    delTList(e) {
      this.term.splice(e, 1);
    },
    handleChange4(v) {
      console.log(v, "province_name");
    //   this.value1s = v;
    },
  },
  components: {},
};
</script>
<style scoped lang="less">
.w600 {
  width: 600px;
  // min-height: 750px;
}


.h400 {
  height: 400px;
}
.gap20 {
  gap: 20px;
}
.gap10 {
  gap: 10px;
}
.pt30 {
  padding-top: 30px;
}
.w100s{
  width: 100px;
}
.w300{
  width: 300px;
}
</style>
