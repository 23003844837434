<template>
  <pop v-show="isPWD">
    <div class="w400">


    <div class="flex jcc aic f16">删除提示</div>
    <div class="pt10 f14">此操作将永久删除,请谨慎操作</div>
    <div class="flex jcc pt10">
      <el-input
        placeholder="请输入密码"
        v-model="input"
        show-password
      ></el-input>
    </div>
    <div class="flex jcc aic gap10 pt10">
      <el-button type="info" @click="cancelPwd">取消</el-button>
      <!-- <div>取消</div>
            <div>确定</div> -->
      <el-button type="primary" @click="handlePwd">确定</el-button>
    </div>
</div>
  </pop>
</template>

<script>
export default {
  data() {
    return {
      input: "",
      isPWD: false,
    };
  },
  components: {},
  methods: {
    handlePwd() {
        this.$root.$emit('pwdNumber',this.input)
    },
    cancelPwd() {
      this.isPWD = false;
    },
    openPwd() {
      this.isPWD = true;
    },
    clearPwd(){
        this.input=''
    },
  },
};
</script>
<style scoped lang="less">
.w400{
    width: 400px;
}
</style>
