// 公共请求
import base from "./base"
import axios from '@/utils/request'
const api = {
  demoApi(params) {
    return axios.get(base.demoApi, params)
  },
  login(data) {
    return axios.post(base.login, data)
  }
}
// import axios from '@/utils/request'
export default api