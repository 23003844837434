<template>
  <div class="overlay" v-show="SearchOpen">
    <div class="pa tps3 w1016 p1015 ovh">
      <div class="flex jcr aic">
        <i class="el-icon-close f20 cp" @click="closeSearch"></i>
      </div>
      <div class="flex jcl aic gap26">
        <div class="w230 textac f16">Product info</div>
        <div class="w664 flex jcl aic">
          <div class="w607">
            <el-input v-model="searchlist" placeholder></el-input>
          </div>
          <div class="w57 bgr flex jcc aic pr cp" @click="startSearch">
            <i class="el-icon-search f23"></i>
          </div>
        </div>
      </div>
      <div class="ovh">
        <div class="ovs1 h58">
          <div class="w986 bgw mt10 br8 cp" v-for="(i,index) in goodslist" :key="index">
            <div class="p12 flex jcl gap12" @click="chosedlist(i)">
              <div class="w128">
                <img class="ofsd" :src="i.cover_image" alt />
              </div>
              <div>
                <div class="tl2 h48 f16s">
                  <i class="Usedw32 f10" v-if="i.new_rate<100">Used</i>
                  {{i.title}}
                </div>
                <div class="pt8" v-html="money2(i.market_price)"></div>
                <div class="flex jcl aic">
                  <i class="gray1 f12">{{i.moq}} Unit (MOQ)|</i>
                  <i class="gray f12" v-if="i.delivery_cycle==0">Ready to Ship</i>
                  <i class="gray f12" v-else>{{i.delivery_cycle}} days</i>
                </div>
                <div class="flex jcl aic pt14 gap4">
            
                  <i class="w28">
                    <img class="ofsd" :src="i.country_icon" alt />
                  </i>
                  <i class="f12 gray">{{i.country_name}}</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
  
  <script type="text/javascript">
export default {
  data() {
    return {
      goodslist: [],
      searchlist: "",
      SearchOpen: false,
      seq: 0
    };
  },

  methods: {
    //请求数据
    async startSearch() {
      let data = {
        kw: this.searchlist,
        page: 1,
        size: 15,
        cid: 0,
        seq: this.seq,
        mt: 0,
        fwd: 0,
        publish_time_Sort: 0,
        price_sort: 0,
        nid: 0,
        price: "",
        begin_publish_time: "",
        end_publish_time: ""
      };
      await this.$axios
        .get(this.webAPI1 + "/home/Search/product", {
          params: data
        })
        .then(res => {
          if (res.code == 200) {
            this.goodslist = res.data.data;
            this.seq = data.seq;
          }
        });
    },
    chosedlist(e) {
  
      this.$root.$emit("addlist", e);
      this.closeSearch();
    },
    closeSearch() {
      this.SearchOpen = false;
    },
    money2(e) {
      let str = "";
      e = e / 100;
      if (e * 1 == 0) {
        str = `<div class='qianqians '>Negotiable</div>`;
      } else {
        let integerPart = Math.floor(e);
        let decimalPart = e.toFixed(2).substring(integerPart.toString().length);
        str = `<div class='qian  red'><span class="red" ><i class="red">US$</i>${integerPart.toLocaleString()}</span><span class='decimal-part1 red'>${decimalPart}</span></div>`;
      }

      return str;
    },
 
  },
  created() {},
  mounted() {
    this.startSearch();
  }
};
</script>
  <style  lang="less" scoped>
  .overlay {
  position: fixed; /* 使用fixed定位，使遮罩固定在视口 */
  top: 0;
  left: 0%;
  width: 100vw; /* 宽度和高度设置为100%，以覆盖整个视口 */
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色背景 */
  z-index: 499; /* 设置一个较高的z-index确保遮罩层在其他内容之上 */
}
.bgw{
  background: #fff;
}
.w1016 {
  width: 986px;
  // height: 84vh;
  border-radius: 8px;
  background: #efefef;
  z-index: 600;
}
.tps3 {
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.p1015 {
  padding: 10px 15px 10px 15px;
}
.w230 {
  width: 230px;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  background: #ff1e1c;
  color: #ffffff;
}
.gap26 {
  gap: 26px;
}
.w664 {
  width: 664px;
  height: 40px;
  background: #fff;
  border-radius: 20px;
  border: 1px solid #ff1e1c;
  border-right: 0;
  overflow: hidden;
}

.w607 {
  width: 607px;
}
.w57 {
  width: 58px;
  height: 40px;
  border-radius: 20px;
  right: -1px;
  background: #ff1e1c;
  color: #fff;
}
input[type="text"]:focus {
  outline: none;
}

input {
  border: none;
}
/deep/ .el-input__inner {
  border: 0;
}
.w986 {
  width: 926px;
  height: 152px;
}
.w128 {
  width: 128px;
  height: 128px;
  img {
    width: 128px;
    height: 128px;
  }
}

.f16s {
  font-size: 16px;
  line-height: 24px;
}
.h48 {
  height: 48px;
}
.w18 {
  width: 18px;
  height: 18px;
  img {
    width: 18px;
    height: 18px;
  }
}
.w28 {
  width: 28px;
  height: 28px;
  img {
    width: 28px;
    height: 28px;
  }
}
.f23{
  font-size: 23px;
}
.pt14 {
  padding-top: 14px;
}
.gap4 {
  gap: 4px;
}
.h58 {
  height: 608px;
}
.w986:hover {
  box-shadow: 0px 0px 6px 0px #00000033;
}
input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}
.lh18 {
  line-height: 18px;
}
.ovs1 {
  overflow: scroll;
  overflow-x: hidden;
}

.ovs2 {
  overflow: scroll;
}

.ovs3 {
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  scroll-behavior: smooth;
}
.p12{
  padding: 12px;
}
.gap12{
  gap: 12px;
}
.gray1 {
  color: #00000066;
}

</style>
  